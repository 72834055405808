import { useEffect, useRef, useState } from "react";
import Ably from "ably";

let ablyInstance = null; // Singleton globale per la connessione Ably
let subscriptions = {}; // Oggetto per gestire le sottoscrizioni per canale

const pubSubService = (channelName, callback) => {
  const [connectionState, setConnectionState] = useState("connecting");
  const [token, setToken] = useState(localStorage.getItem("token")); // Stato per il token
  const callbackRef = useRef(callback); // Memorizza il callback per evitare problemi di riferimento

  useEffect(() => {
    callbackRef.current = callback; // Aggiorna il riferimento al callback ad ogni render
  });

  // Monitorare i cambiamenti nel localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      setToken(localStorage.getItem("token")); // Aggiorna lo stato con il nuovo token
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const prefixedChannelName = `${process.env.REACT_APP_ENV}_${channelName}`;
    console.log("Prefixed channel:", prefixedChannelName);

    // Funzione per inizializzare Ably e la sottoscrizione
    const initializeAbly = async () => {
      if (!token) {
        console.log("No token available, skipping connection.");
        return;
      }

      if (!ablyInstance) {
        try {
          // Inizializza Ably
          ablyInstance = new Ably.Realtime({
            authUrl: `/socketio-auth`,
            authHeaders: {
              Authentication: token, // Passa il token aggiornato
            },
          });

          ablyInstance.connection.on((stateChange) => {
            console.log("Ably connection state changed:", stateChange.current);
            setConnectionState(stateChange.current);
          });
        } catch (error) {
          console.error("Error initializing Ably:", error);
          return;
        }
      }

      // Crea una nuova sottoscrizione se non esiste
      if (!subscriptions[prefixedChannelName]) {
        const channel = ablyInstance.channels.get(prefixedChannelName);

        const subscription = channel.subscribe((message) => {
          console.log(`Message received on ${prefixedChannelName}:`, message);
          if (callbackRef.current) {
            callbackRef.current(message.data);
          }
        });

        subscriptions[prefixedChannelName] = subscription;
        console.log(`Subscribed to channel: ${prefixedChannelName}`);
      }
    };

    // Distruggi la connessione e le sottoscrizioni esistenti
    const cleanupAbly = () => {
      if (subscriptions[prefixedChannelName]) {
        const channel = ablyInstance.channels.get(prefixedChannelName);
        channel.unsubscribe(subscriptions[prefixedChannelName]);
        delete subscriptions[prefixedChannelName];
        console.log(`Unsubscribed from channel: ${prefixedChannelName}`);
      }

      if (ablyInstance && !token) {
        ablyInstance.close();
        ablyInstance = null;
        console.log("Ably connection closed due to missing token.");
      }
    };

    // Inizializza Ably
    initializeAbly();

    // Cleanup on dependency change
    return cleanupAbly;
  }, [channelName, token]); // Ritriggera quando il token cambia o il canale cambia

  return connectionState;
};

export default pubSubService;
