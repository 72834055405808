import React, { useState, useEffect } from "react";
import {
  Box,
  Chip,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import * as Icons from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

// Lista di icone rilevanti per la ristorazione
const relevantIcons = [
  "LocalPizza",
  "Fastfood",
  "Coffee",
  "Restaurant",
  "LunchDining",
  "WineBar",
  "Liquor",
  "Icecream",
  "DinnerDining",
  "SetMeal",
  "RamenDining",
  "Cake",
  "BakeryDining",
  "EmojiFoodBeverage",
  "KebabDining",
  "SoupKitchen",
  "BrunchDining",
];

const ProductFormatSelector = ({
  mode, // "edit" o "view"
  initialChips = [],
  selectedChip: externalSelectedChip = null,
  onChipSelect = () => {},
  onChipsChange = () => {},
}) => {
  const [chips, setChips] = useState(initialChips);
  const [selectedChip, setSelectedChip] = useState(externalSelectedChip);
  const [newChip, setNewChip] = useState({ label: "", price: "", icon: null });
  const [editingIndex, setEditingIndex] = useState(null); // Per tenere traccia dell'indice del chip da modificare
  const [errors, setErrors] = useState({ label: false, price: false });
  const [open, setOpen] = useState(false);
  const [iconModalOpen, setIconModalOpen] = useState(false);
  const theme = useTheme();

  // Sincronizzazione con il chip selezionato esternamente
  useEffect(() => {
    setSelectedChip(externalSelectedChip);
    onChipSelect(externalSelectedChip);
  }, [externalSelectedChip]);

  const handleChipClick = (chip, index) => {
    if (mode === "edit") {
      setEditingIndex(index);
      setNewChip({ ...chip }); // Precompila la modale con i dettagli del chip
      setOpen(true);
    } else {
      setSelectedChip(chip);
      onChipSelect(chip);
    }
  };

  const handleDeleteChip = () => {
    if (editingIndex !== null) {
      const updatedChips = chips.filter((_, index) => index !== editingIndex);
      setChips(updatedChips);
      onChipsChange(updatedChips); // Propaga l'aggiornamento al genitore
      setEditingIndex(null);
      setNewChip({ label: "", price: "", icon: null });
      setOpen(false);
    }
  };

  const validateFields = () => {
    const newErrors = {
      label: !newChip.label.trim(),
      price: !newChip.price || isNaN(newChip.price) || newChip.price <= 0,
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleSaveChip = () => {
    if (!validateFields()) return;

    const updatedChips = [...chips];
    if (editingIndex !== null) {
      // Modifica del chip esistente
      updatedChips[editingIndex] = newChip;
      setChips(updatedChips);
      onChipsChange(updatedChips); // Propaga l'aggiornamento esternamente
    } else {
      // Aggiunta di un nuovo chip
      if (chips.some((chip) => chip.label === newChip.label)) {
        alert("Un'opzione con lo stesso nome esiste già.");
        return;
      }
      updatedChips.push(newChip);
      setChips(updatedChips);
      onChipsChange(updatedChips); // Propaga l'aggiornamento esternamente
    }

    // Resetta i valori e chiudi la modale
    setNewChip({ label: "", price: "", icon: null });
    setEditingIndex(null);
    setOpen(false);
  };

  const renderIcon = (iconName) => {
    if (!iconName) return null; // Nessuna icona
    const IconComponent = Icons[iconName];
    return IconComponent ? <IconComponent /> : null;
  };

  const handleIconSelect = (icon) => {
    setNewChip({ ...newChip, icon });
    setIconModalOpen(false);
  };

  const handleRemoveIcon = () => {
    setNewChip({ ...newChip, icon: null });
    setIconModalOpen(false);
  };

  return (
    <Box>
      {mode === "edit" && (
        <Box mb={2}>
          <Box sx={{ display: "inline-flex" }}>
            <Button
              variant="contained"
              onClick={() => {
                setEditingIndex(null); // Nuova aggiunta
                setNewChip({ label: "", price: "", icon: null });
                setOpen(true);
              }}
            >
              Aggiungi Formato Prodotto
            </Button>
          </Box>
        </Box>
      )}
      <Box display="flex" gap={1} flexWrap="wrap" justifyContent={"center"}>
        {chips.map((chip, index) => (
          <Chip
            key={index}
            label={
              <Box>
                <Typography component="span" variant="button" sx={{whiteSpace:'none'}}>
                  <b>{chip.label}</b> {chip.price}<span style={{fontSize:'8px'}}>€</span>
                </Typography>
              </Box>
            }
            icon={renderIcon(chip.icon)}
            onClick={() => handleChipClick(chip, index)}
            color={selectedChip?.label === chip.label ? "primary" : "default"}
            sx={{
              border:
                selectedChip?.label === chip.label &&
                "solid 2px " + theme.palette.primary.dark,
            }}
            clickable
          />
        ))}
      </Box>

      {/* Modale per aggiungere o modificare un chip */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingIndex !== null
            ? "Modifica Formato"
            : "Aggiungi Nuovo Formato"}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Nome"
            value={newChip.label}
            onChange={(e) => setNewChip({ ...newChip, label: e.target.value })}
            error={errors.label}
            helperText={errors.label ? "Il nome è obbligatorio." : ""}
            margin="normal"
          />
          <TextField
            fullWidth
            type="number"
            label="Prezzo (€)"
            helperText={
              errors.price
                ? "Il prezzo deve essere un numero positivo."
                : "Il prezzo sostituirà il prezzo predefinito del prodotto."
            }
            value={newChip.price}
            onChange={(e) => {
              const value = parseFloat(e.target.value); // Convertire in numero
              setNewChip({ ...newChip, price: isNaN(value) ? "" : value }); // Gestire NaN come valore vuoto
            }}
            error={errors.price}
            margin="normal"
          />

          <Button
            variant="outlined"
            onClick={() => setIconModalOpen(true)}
            fullWidth
            sx={{
              mt: 2,
              justifyContent: "flex-start",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {newChip.icon
              ? renderIcon(newChip.icon)
              : "Seleziona o Modifica Icona"}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Annulla</Button>
          {editingIndex !== null && (
            <Button onClick={handleDeleteChip} color="error" variant="outlined">
              Elimina
            </Button>
          )}
          <Button onClick={handleSaveChip} variant="contained">
            {editingIndex !== null ? "Modifica" : "Aggiungi"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modale Selezione Icona */}
      <Dialog
        open={iconModalOpen}
        onClose={() => setIconModalOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Seleziona o Modifica Icona</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {relevantIcons.map((iconName) => (
              <Grid item xs={3} sm={2} key={iconName}>
                <IconButton
                  onClick={() => handleIconSelect(iconName)}
                  size="large"
                  sx={{
                    border: "1px solid",
                    borderColor:
                      newChip.icon === iconName ? "primary.main" : "grey.300",
                    backgroundColor:
                      newChip.icon === iconName ? "primary.light" : "inherit",
                  }}
                >
                  {renderIcon(iconName)}
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIconModalOpen(false)}>Annulla</Button>
          <Button onClick={handleRemoveIcon} color="error" variant="outlined">
            Rimuovi Icona
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProductFormatSelector;
