import React, { useEffect, useContext } from "react";
import {
  Button,
  Typography,
  Grid,
  Box,
  Stack,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { UserContext } from "../../../store/UserContext";
import OpenTimesDisplay from "./EditLocal/components/OpenTimesDisplay";
import BusinessCard from "./BusinessCard";
import { useTheme } from "@mui/material/styles";

import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation, Trans } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import LocalContactForm from "./Contact/LocalContactForm";
import CirclesEffect from "../../Home/CirclesEffect";

export default function LocalLandingPage() {
  const { IsAllowed, GetLocalIdParams, localData } = useContext(UserContext);
  const theme = useTheme();

  const { local_id, local_name } = useParams();
  const { i18n } = useTranslation();
  const language = i18n.language ? i18n.language.toLowerCase() : "it";

  let navigate = useNavigate();
  const location = useLocation();
  const lastPage = location.state?.from;
  // Verifica se l'URL della pagina precedente corrisponde al pattern desiderato
  const showGoBackMenu =
    lastPage && /\/local\/Menu\/[^/]+\/[^/]+/.test(lastPage);

  function formatDate(dateStr) {
    if (!dateStr) return "Data non disponibile";
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  }

  function ClosingDatesAccordion({ dates = [], ...rest }) {
    if (dates.length === 0) {
      return null;
    }

    return (
      <Box
        sx={{
          ...sectionBackground,
          maxWidth: "400px",

        }}
      >

        <Box>
          <Typography component="div">Date di chiusura:</Typography>

          <Typography component="div">
            <ul>
              {dates.map((date, index) => (
                <li key={index}>
                  {JSON.stringify(date.local_closing_start_date) ===
                  JSON.stringify(date.local_closing_end_date) ? (
                    <>{formatDate(date.local_closing_start_date)}</>
                  ) : (
                    <>
                      Dal: {formatDate(date.local_closing_start_date)}, al:{" "}
                      {formatDate(date.local_closing_end_date)}
                    </>
                  )}
                </li>
              ))}
            </ul>
          </Typography>
        </Box>
      </Box>
    );
  }

  const sectionBackground = {
    padding: "10px",
    borderRadius: "20px",
    //background: backgroundSectionColor,
    maxWidth: "1000px",
    width: "90%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    boxShadow: 1,
    justifySelf: "center",
    backdropFilter: "blur(5px)", // Effetto vetro
    WebkitBackdropFilter: "blur(5px)", // Supporto per Safari
  };
  useEffect(() => {
    GetLocalIdParams(local_id);
  }, []);

  return (
    <div>
      <CirclesEffect
        move={true}
        constantSpeed = {0.6}
        lightCircleColor={`radial-gradient(circle, ${theme.palette.primary.light}, rgba(255, 0, 0, 0))`}
        darkCircleColor={`radial-gradient(circle, ${theme.palette.secondary.light}, rgba(255, 255, 255, 0))`}
      />
      {Object.keys(localData).length === 0 ? (
        <div>
          <Typography variant="h1" color="text.background">
            Loading...
          </Typography>
        </div>
      ) : (
        <div>
          <BusinessCard
            type={localData.business_card_type}
            image={localData.local_cover}
            name={localData.local_name}
            city={localData.local_city}
            cap={localData.local_cap}
            address={localData.local_address}
            phone={localData.local_phone}
            whatsapp={localData.local_whatsapp}
            facebook={localData.facebook}
            site={localData.site}
            latitude={localData.local_latitude}
            longitude={localData.local_longitude}
            instagram={localData.instagram}
            isOpen={localData.isOpen}
            navigateToLandingPage={false}
            table_booking={localData.table_booking}
          />
          <Grid
            container
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {IsAllowed(local_id, ["MANAGER"]) && (
              <Grid item xs={12} md={6}>
                <Stack direction="row" spacing={1} style={{ marginTop: "5px" }}>
                  <Button
                    fullWidth
                    startIcon={<SettingsRoundedIcon />}
                    variant="contained"
                    onClick={() => navigate("/edit_local/" + local_id)}
                  >
                    <Trans>Impostazioni</Trans>
                  </Button>
                </Stack>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              {showGoBackMenu ? (
                <Button
                  sx={{ marginTop: "10px" }}
                  startIcon={<ArrowBackIcon />}
                  fullWidth
                  variant="contained"
                  onClick={() => navigate(-1)}
                >
                  <Trans>Torna indietro</Trans>
                </Button>
              ) : (
                <Button
                  fullWidth
                  sx={{ marginTop: "5px" }}
                  startIcon={<AutoStoriesIcon />}
                  variant="contained"
                  onClick={() => navigate(`/local/menu/${local_id}`)}
                >
                  {" "}
                  <Trans>Vai al menù</Trans>
                </Button>
              )}
            </Grid>
            {localData.local_info && localData.local_info?.[language] && (
              <Grid item xs={12}>
                <Alert
                  severity="info"
                  sx={{
                    marginTop: "10px",
                    justifyContent: "center",
                    ...sectionBackground,
                    color: "black",
                  }}
                >
                  {localData.local_info?.[language]}
                </Alert>
              </Grid>
            )}
            {localData.local_header_info?.[language] && (
              <Grid item xs={12}>
                <Box sx={sectionBackground}>
                  <Typography
                    variant="body"
                    component="div"
                    align="center"
                    color="black"
                    sx={{ marginTop: "10px" }}
                  >
                    {localData.local_header_info[language]}
                  </Typography>
                </Box>
              </Grid>
            )}
            {localData.plan_value > 2 && (
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  {localData.delivery && (
                    <Grid item xs={12} md={4}>
                      <Button
                        style={{ height: "100%" }}
                        startIcon={<DeliveryDiningIcon />}
                        disabled={!localData.delivery}
                        fullWidth
                        variant="contained"
                        onClick={() =>
                          navigate(
                            "/local/menu/" +
                              local_id +
                              "/" +
                              local_name +
                              "/Delivery"
                          )
                        }
                      >
                        <Trans>Ordina e ricevi direttamente a casa</Trans>
                      </Button>
                    </Grid>
                  )}
                  {localData.take_away && (
                    <Grid item xs={12} md={4}>
                      <Button
                        style={{ height: "100%" }}
                        startIcon={<ShoppingBasketIcon />}
                        disabled={!localData.take_away}
                        fullWidth
                        variant="contained"
                        onClick={() =>
                          navigate(
                            "/local/menu/" +
                              local_id +
                              "/" +
                              local_name +
                              "/Take Away"
                          )
                        }
                      >
                        <Trans>Ordina e ritira al locale</Trans>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {localData.local_description && (
              <Grid item xs={12}>
                <Box sx={sectionBackground}>
                  <Typography
                    variant="body"
                    component="div"
                    align="center"
                    color="black"
                    sx={{ marginTop: "10px" }}
                  >
                    {localData.local_description[language]}
                  </Typography>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              {localData.plan_value > 1 && (
                <div style={{marginRight:'5px'}}>
                  <Typography
                    color="text.background"
                    variant="h6"
                    align="center"
                  >
                    Orari di apertura
                  </Typography>
                  <OpenTimesDisplay
                    schedules={localData.local_closing_times}
                    style={{ ...sectionBackground, height: "100%" }}
                  />
                </div>
              )}
            </Grid>
            {localData.plan_value > 1 && (
              <Grid item xs={12} sx={{ justifyItems: "center" }}>
                <ClosingDatesAccordion dates={localData.local_closing_dates} />
              </Grid>
            )}
            <Grid item xs={12}>
              {localData.local_footer_info && (
                <Typography
                  variant="body"
                  component="div"
                  align="center"
                  color="text.background"
                  sx={{ marginTop: "10px" }}
                >
                  {localData.local_footer_info[language]}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={8} lg={7} xl={5}>
              {localData.contactFormStatus && (
                <LocalContactForm sx={{ ...sectionBackground }} />
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}
