import React, { useEffect, useRef } from 'react';
import './CirclesEffect.css';

const CirclesEffect = ({
  move = false,
  followScroll = false,
  lightCircleColor = `radial-gradient(circle, rgba(92, 123, 179, 0.7), rgba(255, 0, 0, 0))`,
  darkCircleColor = `radial-gradient(circle, rgba(99, 2, 106, 0.5), rgba(255, 255, 255, 0))`,
  constantSpeed = 1, // Velocità costante se `move` è true
}) => {
  const angleRef = useRef(0);
  const circle1Ref = useRef();
  const circle2Ref = useRef();
  const requestRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      if (followScroll) {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        angleRef.current = scrollTop * 0.2; // Aggiorna l'angolo in base allo scroll
      }
    };

    if (followScroll) {
      window.addEventListener('scroll', handleScroll);
    }

    const animate = () => {
      if (!followScroll && move) {
        angleRef.current += constantSpeed; // Movimento continuo se `move` è true
      }

      // Calcola le posizioni
      const angle = angleRef.current;
      const angleRad = (angle * Math.PI) / 720;

      const x1 = 50 + Math.cos(angleRad) * 50; // Valori in percentuale
      const y1 = 50 + Math.sin(angleRad) * 50;
      const x2 = 50 - Math.cos(angleRad) * 50;
      const y2 = 50 - Math.sin(angleRad) * 50;

      // Aggiorna le posizioni
      if (circle1Ref.current && circle2Ref.current) {
        circle1Ref.current.style.left = `${x1}vw`;
        circle1Ref.current.style.top = `${y1}vh`;

        circle2Ref.current.style.left = `${x2}vw`;
        circle2Ref.current.style.top = `${y2}vh`;
      }

      requestRef.current = requestAnimationFrame(animate);
    };

    requestRef.current = requestAnimationFrame(animate);

    return () => {
      if (followScroll) {
        window.removeEventListener('scroll', handleScroll);
      }
      cancelAnimationFrame(requestRef.current);
    };
  }, [move, followScroll, constantSpeed]);

  // Applica i colori passati come props
  useEffect(() => {
    if (circle1Ref.current) {
      circle1Ref.current.style.background = lightCircleColor;
    }
    if (circle2Ref.current) {
      circle2Ref.current.style.background = darkCircleColor;
    }
  }, [lightCircleColor, darkCircleColor]);

  return (
    <div className="circle-container">
      <div className="circle light-circle" ref={circle1Ref} />
      <div className="circle dark-circle" ref={circle2Ref} />
    </div>
  );
};

export default CirclesEffect;
