import React, { useState } from 'react';
import { Tooltip, IconButton, Popover } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTheme } from '@mui/material/styles';
import PropTypes from "prop-types";


const InfoMark = ({ description, tooltipContent, show = true, color = 'text.paper', ...rest }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={{ display: !show && 'none' }} {...rest}>
      <Tooltip title={description}>
        <IconButton style={{ color: color? color : theme.palette.text && theme.palette.text.background }} aria-label={description} onClick={handleClick}>
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: '10px',maxHeight:'50vh',overflowY:'auto' }}>
          {tooltipContent ?
            tooltipContent :
            description
          }
        </div>
      </Popover>
    </div>
  );
};

export default InfoMark;

InfoMark.propTypes = {
  show: PropTypes.bool,
  tooltipContent: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  color: PropTypes.string,
  description:PropTypes.string
};
