import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  DialogContentText,
  CircularProgress,
  Box,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UploadImage from "../../Forms/UploadImage";
import { AlertApiContext } from "../../../store/AlertApiContext";
import { useParams } from "react-router-dom";

const SubSectionEditDialog = ({
  open,
  onClose,
  subSection,
  onDeleteSubSection,
  onSave,
}) => {
  const [newName, setNewName] = useState(subSection.subSectionName.it);
  const [newDescription, setNewDescription] = useState(
    subSection.subSectionDescription?.it
  );

  const { local_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [newCover, setNewCover] = useState(subSection.subSectionCover);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const { api_request } = useContext(AlertApiContext);

  const lastUpdatedCached = localStorage.getItem(`lastUpdated-${local_id}`);

  const queryParams = {
    sub_section_id: subSection.subSectionId,
    lastUpdatedCached,
    lastUpdatedCached: new Date().toISOString(), // evita di usare la cache

  };
  async function fetchSubSectionImage() {
    const result = await api_request(
      "GET",
      `/api/products/get_sub_section_image`,
      {
        queryParams,
      }
    );
    setLoading(false);
    if (result.status === "success") {
      const subSectionCover = result.data ? result.data.subSectionCover : null;
      if (subSectionCover && subSectionCover!==' ') {
        return setNewCover(subSectionCover);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  useEffect(() => {
    fetchSubSectionImage();
  }, []);

  const handleSave = () => {
    onSave({
      ...subSection,
      subSectionName: { it: newName },
      subSectionDescription: { it: newDescription },
      subSectionCover: newCover,
    });
    onClose();
  };

  const handleSectionCover = (value) => {
    setNewCover(value || " ");
  };

  const openConfirmDeleteDialog = () => {
    setConfirmDeleteOpen(true);
  };

  const closeConfirmDeleteDialog = () => {
    setConfirmDeleteOpen(false);
  };

  const handleConfirmDelete = () => {
    onDeleteSubSection();
    closeConfirmDeleteDialog();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <DialogTitle>Modifica Sotto-Sezione</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  sx={{ marginTop: "10px" }}
                  fullWidth
                  label="Nome"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Descrizione"
                  multiline
                  rows={4}
                  value={newDescription || ""}
                  onChange={(e) => setNewDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <UploadImage
                  defaultValue={newCover}
                  disableAlpha={false}
                  onUpload={handleSectionCover}
                  onUrl={handleSectionCover}
                  maxSize={0.5 * 1024 * 1024}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              startIcon={<DeleteForeverIcon />}
              onClick={openConfirmDeleteDialog}
              color="error"
            >
              Elimina
            </Button>
            <Button sx={{backgroundColor:'grey'}} onClick={onClose} color="primary">
              Annulla
            </Button>
            <Button onClick={handleSave} color="primary">
              Salva
            </Button>
          </DialogActions>

          {/* Dialog di conferma per la cancellazione */}
          <Dialog open={confirmDeleteOpen} onClose={closeConfirmDeleteDialog}>
            <DialogTitle>Conferma cancellazione</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Sei sicuro di voler eliminare questa sotto-sezione? Questa
                azione è irreversibile.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeConfirmDeleteDialog} color="primary">
                Annulla
              </Button>
              <Button onClick={handleConfirmDelete} color="error">
                Conferma
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Dialog>
  );
};

export default SubSectionEditDialog;
