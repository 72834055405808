import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../Style/Loading.css";
import {
  Card,
  Typography,
  Divider,
  Box,
  ButtonBase,
  Stack,
  Grid,
  IconButton,
} from "@mui/material";
import RenderIngredients from "./RenderIngredients";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import ModalConfirm from "../Modals/ModalConfirm";
import { GetNameByLanguage } from "../Functions/LanguageFunctions";
import { productSteps } from "./ProductFunctions";
import "./ProductsStyle.css";

const ProductStatusCard = ({
  product,
  orderStep,
  onChange,
  handle = false,
  stopLoading,
  ...rest
}) => {
  const {
    product_name,
    product_price,
    product_currency = "€",
    quantity,
    product_message,
    productIngredientsVar,
    handler_user_name,
    productFormatSelected,
    product_status: initialActiveStep = undefined,
  } = product;

  const [activeStep, setActiveStep] = useState(initialActiveStep);
  const [loading, setLoading] = useState(false);

  // Use effect to sync activeStep state with prop changes
  useEffect(() => {
    setLoading(false);
  }, [stopLoading]);

  useEffect(() => {
    setActiveStep(initialActiveStep);
  }, [initialActiveStep]);
  const step = productSteps[activeStep];

  const [openModal, setOpenModal] = useState(false);

  function handleNextStep(checkConfirm = true) {
    if (handle) {
      if (activeStep + 1 < Object.keys(productSteps).length) {
        const newActiveStep =
          (activeStep + 1) % Object.keys(productSteps).length;

        // Check product and order status
        if (newActiveStep === 1 && orderStep < 1 && checkConfirm) {
          // Open modal and return to prevent the step from advancing
          setOpenModal(true);
          return;
        }

        setActiveStep(newActiveStep);
        // Notify parent component
        if (onChange) {
          onChange(newActiveStep);
          setLoading(true);
        }
      }
    }
  }

  const handlePrevStep = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      onChange(activeStep - 1);
      setLoading(true);
    }
  };

  return (
    <>
      <ModalConfirm
        open={openModal}
        setOpen={setOpenModal}
        header={"Ordine non confermato"}
        body={
          "L'ordine non è stato confermato, sei sicuro di voler procedere con la preparazione e conferma dell'ordine?"
        }
        onConfirm={(confirmed) => {
          handleNextStep(false);
        }} // Definisci cosa succede quando l'utente conferma
        // Altre props...
      />

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "5px" }}
        {...rest}
      >
        {activeStep > 0 && handle && (
          <IconButton
            onClick={handlePrevStep}
            sx={{
              backgroundColor: step.color,
              width: "40px",
              height: "40px",
              boxShadow: 3,
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <ButtonBase
          sx={{
            borderRadius: "5px",
            margin: "0px",
            width: "100%",
            height: "100%",
          }}
          onClick={handleNextStep}
          disabled={!handle}
        >
          <Card
            className={loading ? "effect-wave" : undefined}
            sx={{
              backgroundColor: step?.color || "white",
              width: "100%",
              height: "100%",
            }}
          >
            <Stack direction="column" spacing={0} divider={<Divider />}>
              <Box
                alignItems="center"
                sx={{
                  background: activeStep > 0 ? "#00000038" : "#f8f8f8",
                  color: activeStep > 0 ? "white" : "black",
                }}
              >
                <Box display="flex">
                  <Grid container
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={0}
                  >
                    <Stack direction="column">
                      <Stack direction="row">
                        {step?.icon}
                        <Typography variant="subtitle1" textAlign="left">
                          {handler_user_name}
                        </Typography>
                      </Stack>

                      <Typography
                        variant="subtitle1"
                        component="div"
                        textAlign="left"
                      >
                        {quantity}
                      </Typography>
                    </Stack>

                    <Typography
                      variant="subtitle1"
                      component="div"
                      sx={{ fontWeight: "bold" }}
                    >
                      {GetNameByLanguage(product_name)}
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      component="div"
                      textAlign="right"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {product_price} {product_currency}
                    </Typography>
                  </Grid>
                </Box>
              </Box>

              {product_message && (
                <>
                  <Typography
                    variant="subtitle1"
                    textAlign="left"
                    sx={{ color: "text.secondary" }}
                  >
                    Messaggio: {product_message}
                  </Typography>
                </>
              )}
              {productFormatSelected && productFormatSelected.length>0 && (
                <li>
                  <Typography
                    color="text.paper"
                    style={{ fontSize: "14px" }}
                    component="span"
                    variant="body1"
                  >
                    {productFormatSelected.icon}{" "}
                    {productFormatSelected.label}{" "}
                    {productFormatSelected.price}€
                  </Typography>
                </li>
              )}

              <RenderIngredients
                productIngredientsVar={productIngredientsVar}
                product_currency={product_currency}
              />
            </Stack>
          </Card>
        </ButtonBase>
      </Stack>
    </>
  );
};

export default ProductStatusCard;

ProductStatusCard.propTypes = {
  onChange: PropTypes.func,
};
