import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

export const useParallaxScroll = (speed = 25) => {
  const ref = useRef(null);
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;
      const rect = ref.current.getBoundingClientRect();
      const offsetTop = rect.top;
      const windowHeight = window.innerHeight;

      // Calcolo del centro dello schermo
      const centerPosition = windowHeight / 2 - rect.height / 2;

      if (offsetTop < windowHeight && offsetTop + rect.height > 0) {
        const parallaxEffect = ((centerPosition - offsetTop) / windowHeight) * speed;
        setOffsetY(parallaxEffect);
      }
    };

    const debounceScroll = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", debounceScroll);
    return () => window.removeEventListener("scroll", debounceScroll);
  }, [speed]);

  return [ref, offsetY];
};

export function RenderParallaxImage({
  url,
  speed = 25,
  clipPath,
  children,
  height= undefined,

  ...rest
}) {
  const [cardRef, offsetY] = useParallaxScroll(speed);

  if (!url) return null;

  return (
    <div ref={cardRef} {...rest}>
      <div
        className="parallax-container"
        style={{
          clipPath,
        }}
      >
        <div
          {...rest}
          className="parallax-image"
          style={{
            transform: `translateY(${offsetY}%)`,
            backgroundImage: `url(${url})`,
            willChange: "transform", // Ottimizza il rendering
            height: "150%", 
            top:'-25%',
            position:'absolute'
          }}
        >

            {children}

        </div>
      </div>
    </div>
  );
}

RenderParallaxImage.propTypes = {
  url: PropTypes.string.isRequired,
  speed: PropTypes.number,
  clipPath: PropTypes.string,
  children: PropTypes.node,
};

export default RenderParallaxImage;
