import React, { useState, useRef, useEffect } from "react";
import { Card, Box } from "@mui/material";
import Slider from "react-slick";

export default function CarouselCard({
  backgroundColor,
  height = "200px",
  highlightColor = "grey",
  title,
  contents,
  autoSlideDuration,
  ...rest
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Disattiva i pulsanti laterali
    beforeChange: (current, next) => setCurrentIndex(next), // semplicemente imposta l'index corrente
  };

  useEffect(() => {
    let progressInterval; // dichiaralo qui in modo che possa essere utilizzato nell'intero effetto
    if (autoSlideDuration) {
      progressInterval = setInterval(() => {
        setProgress((prev) => {
          if (prev < 100) return prev + 1;
          return prev;
        });
      }, autoSlideDuration / 100);
    }
    return () => clearInterval(progressInterval); // pulisci l'intervallo quando il componente si smonta o autoSlideDuration cambia
  }, [autoSlideDuration]);

  useEffect(() => {
    if (progress >= 100) {
      sliderRef.current.slickNext();
      setProgress(0);
    }
  }, [progress]);

  const dotStyle = (index) => {
    const baseStyle = {
      width: 20,
      height: 5,
      margin: 5,
      cursor: "pointer",
      borderRadius: "10px",
      backgroundColor: "rgba(128, 128, 128, 0.5)",
      border: `0px solid ${highlightColor}`,
    };

    if (index < currentIndex) {
      return { ...baseStyle, backgroundColor: highlightColor };
    } else if (index === currentIndex && autoSlideDuration) {
      return {
        ...baseStyle,
        background: `linear-gradient(to right, ${highlightColor} ${progress}%, rgba(128, 128, 128, 0.5) ${progress}%)`,
      };
    }
    return baseStyle;
  };

  return (
    <Card
      sx={{
        position: "relative",
        background: backgroundColor,
        padding: "0px",
        boxShadow: 0,
        borderRadius: "10px",
      }}
      {...rest}
    >
      <Slider
        ref={sliderRef}
        {...settings}
        style={{ width: "100%", minHeight: height, maxHeight: "90vh" }}
      >
        {contents.map((content, index) => (
          <Box key={index} sx={{ height: "100%" }}>
            <Box sx={{ margin: "0px", height: height }}>{content}</Box>
          </Box>
        ))}
      </Slider>
      {title && <Box sx={{ position: "absolute", top: "0px" }}>{title}</Box>}
      <Box
        sx={{
          position: "absolute",
          bottom: "0px",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start",
          marginTop: "10px",
          padding: "5px",
        }}
      >
        <Box sx={{backgroundColor:'rgba(128, 128, 128, 0)', display:'flex',margin:'2px', borderRadius:'5px', padding:'2px'}}>
          {contents.map((_, index) => (
            <Box
              key={index}
              onClick={() => {
                setProgress(0);
                sliderRef.current.slickGoTo(index);
              }}
              sx={{
                boxShadow: 1,
                backgroundColor: "rgba(128, 128, 128, 0.5)",
              }}
              style={dotStyle(index)}
            />
          ))}
        </Box>
      </Box>
    </Card>
  );
}
