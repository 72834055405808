import React from "react";
import {
  Stack,
  Divider,
  Card,
  Grid,
  Box,
  Typography,
  ButtonBase,
} from "@mui/material";
import ProductImage from "./ProductImage";
import { RenderIcon } from "./RenderIcon";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import "./CardStyle.css";
import { getContrastColors } from "../Functions/Colors";
import { RenderParallaxImage } from "../Animations/UseParallaxScroll";
import { Trans } from "react-i18next";
import DynamicFontTypography from "../Functions/DynamicFontTypography";

function ProductCard(props) {
  const theme = useTheme();
  const {
    variant = "default",
    local_id = 1,
    product_id = 1,
    language = "it",
    menuCardShadow = 2,
    product_name = undefined,
    section_card_color = "#ffffff",
    product_description = undefined,
    product_storytelling = undefined,
    product_perfect_service = undefined,
    product_price = 0,
    product_cover,
    product_currency = "€",
    productIngredients = [],
    productAllergens = [],
    productCategoriesFood = [],
    onClick,
    ...rest
  } = props;
  const fontColor = getContrastColors("#000000", section_card_color);

  function truncateText(text, maxLength) {
    try {
      if (text.length && text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      } else {
        return text;
      }
    } catch {
      return text;
    }
  }

  function openModal() {
    onClick(true);
    window.history.pushState("fake-route", document.title, "");
    window.addEventListener("popstate", (event) => {});
  }

  const buttonBaseProps = {
    id: `product-card-button-${product_name}`,
  };

  function StandardCard({}) {
    const colorContrastPrimary = theme.palette.getContrastText(
      theme.palette.primary.dark
    );
    const sectionFontColor = theme.palette.getContrastText(section_card_color);
    return (
      <ButtonBase
        {...buttonBaseProps}
        onClick={() => openModal()}
        sx={{
          boxShadow: menuCardShadow,
          backgroundColor: section_card_color,
          color: fontColor,
        }}
        style={{
          background: `url(${product_cover})`,
          backgroundPosition: "center",
          backgroundColor: section_card_color,
          color: sectionFontColor,
          backgroundSize: "cover",
          minHeight: "15vh",
          borderRadius: "5px",
          border: "solid 0px",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: section_card_color,
            border: `solid 0px ${colorContrastPrimary}`,
            borderRadius: "5px",
            padding: "0px",
            margin: "0px",
          }}
        >
          <Typography
            style={{ textAlign: "center" }}
            component="div"
            variant="h6"
          >
            {product_name}
          </Typography>
          <Typography
            component="div"
            variant="h5"
            style={{ whiteSpace: "nowrap" }}
          >
            {product_price + " " + product_currency}
          </Typography>
        </Box>
      </ButtonBase>
    );
  }

  function NoImageCard({}) {
    return (
      <Card
        {...buttonBaseProps}
        onClick={() => openModal()}
        sx={{
          boxShadow: menuCardShadow,
          backgroundColor: section_card_color,
          color: fontColor,
        }}
        style={{
          display: "inline-flex",
          border: "solid 0px",
          height: "100%",
          width: "100%",
        }}
      >
        <ButtonBase style={{ width: "100%", padding: "5px" }}>
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Typography
              style={{ textAlign: "left" }}
              component="div"
              variant="h6"
            >
              {product_name}
            </Typography>
            <Typography
              component="div"
              variant="h6"
              style={{ whiteSpace: "nowrap" }}
            >
              {product_price + " " + product_currency}
            </Typography>
          </Stack>
        </ButtonBase>
      </Card>
    );
  }

  function NoBackgroundCard({}) {
    return (
      <Box
        sx={{
          boxShadow: menuCardShadow,
          backgroundColor: section_card_color,
          color: fontColor,
        }}
        onClick={() => openModal()}
        style={{
          display: "inline-flex",
          border: "solid 1px #00000040",
          height: "100%",
          width: "100%",
        }}
      >
        <ButtonBase
          {...buttonBaseProps}
          style={{ width: "100%", padding: "2px" }}
        >
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Stack direction="row" spacing={0} alignItems="center">
              {product_cover && (
                <img
                  style={{
                    boxShadow: 2,
                    width: "60px",
                    height: "60px",
                    margin: "0px",
                    borderRadius: "5px",
                  }}
                  src={product_cover}
                  onContextMenu={(event) => event.preventDefault()}
                  alt={product_name}
                />
              )}
              <Typography
                align="left"
                component="div"
                variant="h6"
                sx={{ margin: "2px" }}
              >
                {product_name}
              </Typography>
            </Stack>
            <Typography
              component="div"
              variant="h5"
              style={{ whiteSpace: "nowrap" }}
            >
              {product_price + " " + product_currency}
            </Typography>
          </Stack>
        </ButtonBase>
      </Box>
    );
  }

  function DefaultCard({}) {
    return (
      <Box sx={{ padding: "0px" }}>
        <Card
          {...buttonBaseProps}
          sx={{
            boxShadow: menuCardShadow,
            backgroundColor: section_card_color,
            color: fontColor,
          }}
          onClick={() => openModal()}
          style={{
            display: "inline-flex",
            border: "solid 0px",
            height: "100%",
            width: "100%",
          }}
        >
          <ProductImage
            style={{ width: 90 }}
            local_id={local_id}
            product_name={product_name}
            product_cover={product_cover}
          />

          <ButtonBase style={{ width: "100%", padding: "5px" }}>
            <Stack
              spacing={0}
              sx={{ width: "100%" }}
              divider={<Divider orientation="horizontal" flexItem />}
            >
              <Stack
                spacing={0}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  style={{ textAlign: "left" }}
                  component="div"
                  variant="h6"
                >
                  {product_name}
                </Typography>
                <Typography
                  component="div"
                  variant="h5"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {product_price + " " + product_currency}
                </Typography>
              </Stack>

              <Stack
                spacing={0}
                direction="row"
                style={{ width: "100%", padding: "0px 0px 10px 0px" }}
              >
                <Grid container spacing={1} style={{ padding: "5px" }}>
                  <Grid style={{ textAlign: "left" }} item xs={12}>
                    {product_description != "" &&
                      product_description != undefined &&
                      product_description != null && (
                        <Typography variant="body2" component="div">
                          {product_description}
                        </Typography>
                      )}
                    <>
                      {product_perfect_service &&
                        product_perfect_service != "" &&
                        product_perfect_service != undefined &&
                        product_perfect_service != null && (
                          <Typography variant="body2" component="div">
                            <b>
                              <Trans>Servizio</Trans>:
                            </b>{" "}
                            {product_perfect_service}
                          </Typography>
                        )}
                      <div style={{ display: "contents" }}>
                        {productAllergens != null &&
                          productAllergens.length > 0 &&
                          productAllergens.map((allergen_name) => (
                            <RenderIcon
                              key={
                                "allergen-" + product_id + "-" + allergen_name
                              }
                              iconName={allergen_name}
                              backgroundColor={theme.palette.primary.dark}
                              color="white"
                              showText={false}
                            />
                          ))}
                        {productCategoriesFood != null &&
                          productCategoriesFood.length > 0 &&
                          productCategoriesFood.map((productCategory) => (
                            <RenderIcon
                              key={
                                "categoryFood-" +
                                product_id +
                                "-" +
                                productCategory
                              }
                              iconName={productCategory}
                              backgroundColor={theme.palette.secondary.dark}
                              color={"white"}
                              showText={false}
                            />
                          ))}
                      </div>
                    </>
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </ButtonBase>
        </Card>
      </Box>
    );
  }

  function MinimalCard({}) {
    return (
      <Card
        {...buttonBaseProps}
        sx={{
          boxShadow: menuCardShadow,
          backgroundColor: section_card_color,
          color: fontColor,
        }}
        onClick={() => openModal()}
        style={{
          display: "inline-flex",
          border: "solid 0px",
          height: "100%",
          width: "100%",
        }}
      >
        <ProductImage
          style={{ width: 90 }}
          local_id={local_id}
          product_name={product_name}
          product_cover={product_cover}
        />

        <ButtonBase style={{ width: "100%", padding: "5px" }}>
          <Stack
            spacing={0}
            sx={{ width: "100%" }}
            divider={<Divider orientation="horizontal" flexItem />}
          >
            <Grid
              spacing={0}
              container
              style={{ width: "100%", alignItems: "center" }}
            >
              <Grid item xs={9} sm={10}>
                <Typography
                  style={{ textAlign: "left" }}
                  component="div"
                  variant="h5"
                >
                  {product_name}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={2} style={{ textAlign: "right" }}>
                <Typography
                  component="div"
                  variant="h5"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {product_price + " " + product_currency}
                </Typography>
              </Grid>
            </Grid>
            <Stack
              spacing={0}
              direction="row"
              style={{ width: "100%", padding: "0px 0px 10px 0px" }}
            >
              <Grid container spacing={1} style={{ padding: "5px" }}>
                <Grid style={{ textAlign: "left" }} item xs={12}>
                  <>
                    <div style={{ display: "contents" }}>
                      {productAllergens != null &&
                        productAllergens.length > 0 &&
                        productAllergens.map((allergen_name) => (
                          <RenderIcon
                            key={"allergen-" + product_id + "-" + allergen_name}
                            iconName={allergen_name}
                            backgroundColor={theme.palette.primary.dark}
                            color="white"
                            showText={false}
                          />
                        ))}
                      {productCategoriesFood != null &&
                        productCategoriesFood.length > 0 &&
                        productCategoriesFood.map((productCategory) => (
                          <RenderIcon
                            key={
                              "categoryFood-" +
                              product_id +
                              "-" +
                              productCategory
                            }
                            iconName={productCategory}
                            backgroundColor={theme.palette.secondary.dark}
                            color={"white"}
                            showText={false}
                          />
                        ))}
                    </div>
                  </>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </ButtonBase>
      </Card>
    );
  }

  function SplitCard({}) {
    return (
      <Card
        {...buttonBaseProps}
        onClick={() => openModal()}
        sx={{
          boxShadow: menuCardShadow,
          backgroundImage: `url(${product_cover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "inline-flex",
          border: "solid 0px",
          height: "100%",
          width: "100%",
        }}
      >
        <ButtonBase style={{ width: "100%", padding: "5px" }}>
          <Stack
            spacing={0}
            sx={{ width: "100%" }}
            divider={<Divider orientation="horizontal" flexItem />}
          >
            <Grid
              spacing={0}
              container
              style={{
                width: "100%",
                alignItems: "center",
                backgroundColor: "#000000a8",
                borderRadius: "5px",
                padding: "5px",
              }}
            >
              <Grid item xs={9} sm={10}>
                <Typography
                  style={{ textAlign: "left" }}
                  component="div"
                  variant="h5"
                  color="white"
                >
                  {product_name}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={2} style={{ textAlign: "right" }}>
                <Typography color="white" component="div" variant="h5">
                  {product_price + " " + product_currency}
                </Typography>
              </Grid>
              <Stack
                spacing={0}
                direction="row"
                style={{ width: "100%", padding: "0px 0px 10px 0px" }}
              >
                <Grid container spacing={1} style={{ padding: "5px" }}>
                  <Grid style={{ textAlign: "left" }} item xs={12}>
                    <>
                      <div style={{ display: "contents" }}>
                        {productAllergens != null &&
                          productAllergens.length > 0 &&
                          productAllergens.map((allergen_name) => (
                            <RenderIcon
                              key={
                                "allergen-" + product_id + "-" + allergen_name
                              }
                              iconName={allergen_name}
                              backgroundColor={theme.palette.primary.dark}
                              color="white"
                              showText={false}
                            />
                          ))}
                        {productCategoriesFood != null &&
                          productCategoriesFood.length > 0 &&
                          productCategoriesFood.map((productCategory) => (
                            <RenderIcon
                              key={
                                "categoryFood-" +
                                product_id +
                                "-" +
                                productCategory
                              }
                              iconName={productCategory}
                              backgroundColor={theme.palette.secondary.dark}
                              color={"white"}
                              showText={false}
                            />
                          ))}
                      </div>
                    </>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Stack>
        </ButtonBase>
      </Card>
    );
  }

  function ImageCard({}) {
    const ImageButton = styled(ButtonBase)(({ theme }) => ({
      position: "relative",
      height: 200,
      [theme.breakpoints.down("sm")]: {
        width: "100% !important", // Overrides inline-style
        height: 100,
      },
      "&:hover, &.Mui-focusVisible": {
        zIndex: 1,
        "& .MuiImageBackdrop-root": {
          opacity: 0.15,
        },
        "& .MuiImageMarked-root": {
          opacity: 0,
        },
        "& .MuiTypography-root": {
          color: "white",
          borderRadius: "5px",
          backgroundColor: 'rgb(0 0 0 / 72%)',
          border: "0px solid white",
          backgroundOpacity: 1,
          transition: theme.transitions.create("opacity"),
        },
      },
    }));

    const ImageSrc = styled("span")({
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      borderRadius: "5px",
      backgroundPosition: "center 50%",
    });

    const Image = styled("span")(({ theme }) => ({
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.common.white,
    }));

    const ImageBackdrop = styled("span")(({ theme }) => ({
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: "black",
      opacity: 0.45,
      transition: theme.transitions.create("opacity"),
    }));

    const ImageMarked = styled("span")(({ theme }) => ({
      height: 3,
      width: 18,
      backgroundColor: "rgb(0 0 0 / 72%)",
      borderRadius: "5px",
      position: "absolute",
      bottom: -3,
      left: "calc(50% - 9px)",
      transition: theme.transitions.create("opacity"),
    }));

    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          minWidth: 100,
          width: "100%",
          boxShadow: menuCardShadow,
          borderRadius: "5px",
        }}
      >
        <ImageButton
          {...buttonBaseProps}
          style={{ borderRadius: "5px", width: "100%" }}
          focusRipple
          key={product_id}
          onClick={() => openModal()}
        >
          <ImageSrc style={{ backgroundImage: `url(${product_cover})` }} />
          <ImageBackdrop
            sx={{ boxShadow: 3 }}
            style={{ borderRadius: "5px",padding:'1px' }}
            className="MuiImageBackdrop-root"
          />
          <Image>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <DynamicFontTypography
                text={product_name}
                variant="subtitle1"
                align="center"
              />
            </Box>
          </Image>
        </ImageButton>
      </Box>
    );
  }

  function ImageLeftCard({}) {
    return (
      <Card
        sx={{
          width: "100%",
          display: "flex",
          height: "100%",
          boxShadow: menuCardShadow,
          backgroundColor: section_card_color,
          color: fontColor,
        }}
      >
        <ButtonBase
          {...buttonBaseProps}
          onClick={() => openModal()}
          sx={{ width: "100%", display: "flex" }}
        >
          <Box
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "100%",
              width: "30%",
              backgroundImage: `url(${product_cover})`,
            }}
          />
          <Stack sx={{ width: "70%", padding: 1 }} spacing={1}>
            <Typography variant="h6">{product_name}</Typography>
            <Typography variant="h5">
              {product_price + " " + product_currency}
            </Typography>
          </Stack>
        </ButtonBase>
      </Card>
    );
  }

  function CentralImageCard({}) {
    return (
      <Card
        sx={{
          width: "100%",
          boxShadow: menuCardShadow,
          backgroundColor: section_card_color,
          color: fontColor,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ButtonBase
          {...buttonBaseProps}
          onClick={() => openModal()}
          sx={{
            width: "100%",
            padding: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {product_cover && (
            <Box
              sx={{
                boxShadow: 1,
                height: "40vh",
                borderRadius: 2,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                backgroundImage: `url(${product_cover})`,
              }}
            />
          )}
          <Stack sx={{ width: "100%", padding: 1 }} spacing={1}>
            <Typography variant="h6" textAlign="center">
              {product_name}
            </Typography>
            <Typography variant="h5" textAlign="center">
              {product_price + " " + product_currency}
            </Typography>
          </Stack>
        </ButtonBase>
      </Card>
    );
  }

  function ModernParallax({}) {
    return (
      <ButtonBase
        {...buttonBaseProps}
        sx={{ width: "100%" }}
        onClick={() => openModal()}
      >
        <Card
          sx={{
            width: "100%",
            display: "flex",
            height: "200px",
            boxShadow: menuCardShadow,
            overflow: "hidden",
            backgroundColor: section_card_color,
            color: fontColor,
          }}
        >
          {product_cover && (
            <div
              style={{
                width: "200%",
              }}
            >
              <RenderParallaxImage
                url={product_cover}
                style={{ height: "200px" }}
                clipPath={"polygon(0px 0px, 70% 0px, 100% 100%, 0px 100%)"}
                speed={10}
              />
            </div>
          )}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              position: "relative",
            }}
          >
            <Stack
              sx={{
                width: product_cover ? "130%" : "100%",
                padding: 1,
                marginLeft: product_cover && "-30%",
              }}
              spacing={1}
            >
              <DynamicFontTypography text={product_name} align="right" />

              <Typography variant="h6" align="right" sx={{fontSize:'25px', position:'absolute',bottom:'0px',right:'5px'}}>
                {product_price + " " + product_currency}
              </Typography>
            </Stack>
          </Box>
        </Card>
      </ButtonBase>
    );
  }

  function CircularTopImageCard({}) {
    return (
      <Box
        sx={{
          width: "100%",
          marginTop: "50px",
          position: "relative",
          borderRadius: "10px",
          boxShadow: menuCardShadow,
          color: fontColor,
          backgroundColor: section_card_color,
        }}
      >
        {" "}
        {/* Aggiustato pt per compensare il margine superiore negativo dell'immagine */}
        <ButtonBase
          {...buttonBaseProps}
          sx={{
            display: "contents",
            borderRadius: "10px",
            backgroundColor: section_card_color,
          }}
          onClick={() => openModal()}
        >
          {/* Immagine centrale */}
          {product_cover && (
            <Box
              style={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "absolute",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
                backgroundImage: `url(${product_cover})`,
                width: 100, // Imposta la larghezza desiderata
                height: 100, // Imposta l'altezza desiderata
                borderRadius: "50%", // Rende l'immagine circolare
                objectFit: "cover", // Mantiene le proporzioni dell'immagine
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "-50px", // Sposta l'immagine verso l'alto di metà della sua altezza
                zIndex: 1,
              }}
            />
          )}

          {/* Nome del prodotto */}
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              textAlign: "center",
              paddingTop: "50px",
              marginBottom: "0px",
            }}
          >
            {product_name}
          </Typography>

          {/* Descrizione del prodotto */}
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            {product_description}
          </Typography>

          {/* Linea divisoria */}
          <Divider />

          {/* Prezzo del prodotto */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ padding: "5px", width: "100%" }}
          >
            <Typography variant="h6" component="div" sx={{ textAlign: "left" }}>
              {product_currency}
              {product_price}
            </Typography>
            <Box>
              {productCategoriesFood != null &&
                productCategoriesFood.length > 0 &&
                productCategoriesFood.map((productCategory) => (
                  <RenderIcon
                    key={"categoryFood-" + product_id + "-" + productCategory}
                    iconName={productCategory}
                    backgroundColor={theme.palette.secondary.dark}
                    color={"white"}
                    showText={false}
                  />
                ))}
            </Box>
          </Stack>
        </ButtonBase>
      </Box>
    );
  }

  function GlassEffectCard({}) {
    return (
      <Card
        sx={{
          width: "100%",
          height: "350px",
          position: "relative",
          borderRadius: "20px",
          overflow: "hidden",
          boxShadow: menuCardShadow,
          margin: "0px",
          backgroundColor: section_card_color,
        }}
      >
        <ButtonBase
          {...buttonBaseProps}
          sx={{
            display: "contents",
            borderRadius: "20px",
            backgroundColor: section_card_color,
          }}
          onClick={() => openModal()}
        >
          <Box
            sx={{
              height: "100%",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundImage:
                  "linear-gradient(to top, rgba(0,0,0,0.8) 30%,  transparent 100%)",
                borderRadius: "20px",
              },
            }}
          >
            {product_cover && (
              <img
                src={product_cover}
                alt={product_name}
                loading="lazy"
                style={{
                  width: "100%",
                  height: "350px",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: 10,
              width: "100%",
              color: "white",
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ textAlign: "center" }}
            >
              {product_name}
            </Typography>
            <Box sx={{ padding: "0px 10px 0px 10px" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ padding: "0px", width: "100%" }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ textAlign: "left", textWrap: "nowrap" }}
                >
                  {product_currency} {product_price}
                </Typography>
                <Box>
                  {productCategoriesFood != null &&
                    productCategoriesFood.length > 0 &&
                    productCategoriesFood.map((productCategory) => (
                      <RenderIcon
                        key={
                          "categoryFood-" + product_id + "-" + productCategory
                        }
                        iconName={productCategory}
                        backgroundColor={theme.palette.secondary.dark}
                        color={"white"}
                        showText={false}
                      />
                    ))}
                </Box>
              </Stack>
            </Box>
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              {truncateText(product_description, 100)}
            </Typography>
          </Box>
        </ButtonBase>
      </Card>
    );
  }

  function CardVariant({ variant }) {
    if (variant === "default") {
      return <DefaultCard />;
    }
    if (variant === "minimal") {
      return <MinimalCard />;
    }
    if (variant === "split") {
      return <SplitCard />;
    }
    if (variant === "image") {
      return <ImageCard />;
    }
    if (variant === "standard") {
      return <StandardCard />;
    }
    if (variant === "no-image-standard") {
      return <NoImageCard />;
    }
    if (variant === "no-background") {
      return <NoBackgroundCard />;
    }
    if (variant === "image-center") {
      return <CentralImageCard />;
    }
    if (variant === "image-left") {
      return <ImageLeftCard />;
    }

    if (variant == "modern-parallax") {
      return <ModernParallax />;
    }

    if (variant == "circular-top-image") {
      return <CircularTopImageCard />;
    }

    if (variant == "glass-effect") {
      return <GlassEffectCard />;
    }

    return <DefaultCard />;
  }

  return <CardVariant variant={variant} />;
}

export default ProductCard;
