import React, { useState, useEffect, useContext } from 'react';

import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import { AlertApiContext } from '../../../../../store/AlertApiContext';
import { UserContext } from '../../../../../store/UserContext'
import BusinessCard from '../../BusinessCard'
/*import {handleResponse} from '../../api/api'*/
import { Typography, Grid} from '@mui/material';
import LoadingForm from './LoadingForm'

import { ColorPicker,SelectButton } from '../../../../../story-components/components';




export const fonts = [
    'Roboto',
    'Lato',
    'Montserrat Alternates',
    'Oswald',
    'Georgia',
    'Courier New',
    'Monospace',
    'Kalam',
    'Permanent Marker',
    'Pushster',
    'Fredericka the Great',
    'Anton',
    'Caveat',
    'Indie Flower',
    'Rakkas',
    'Sigmar One',
    'Poiret One',
    'Gloria Hallelujah',
    'Dancing Script',
    'Amatic SC',
    //'Cursive',
    'Satisfy',
    'Nixie One',
    'Rancho',
    'Pacifico',
    'Bangers',
    'Rubik Maze',
    'Shadows Into Light',
    'Abril Fatface',
    'Rock Salt',
];


export default function LocalInfo({ local_id, buttonClicked, onSaved }) {

    const theme = useTheme();
    const { api_request } = useContext(AlertApiContext);
    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)
    const [businessCardType, setBusinessCardType] = useState(undefined)
    const { setPrimaryColor, primaryColor, secondaryColor, setSecondaryColor, bodyFontFamily, setBodyFontFamily, buttonFontFamily, setButtonFontFamily, headerFontFamily, setHeaderFontFamily, backgroundColor, setBackgroundColor, clearLocalParamsCache } = useContext(UserContext);


    async function getData() {
        setLoading(true)
        const queryParams = {
            local_id,
            data_type: 'local_style'
        }
        const response = await api_request('GET', `/api/locals/settings`, { queryParams })
        if (response.status === 'success') {
            setData(response.data)
            setBusinessCardType(response.data.business_card_type)
            setLoading(false)
        }

    }

    useEffect(() => {
        getData()
    }, [])



    const [data, setData] = useState({})

    useEffect(() => {
        if (buttonClicked) {
            onSave();

        }
    }, [buttonClicked]);

    async function onSave() {
        setSaving(true)
        const body = {
            local_primary_color: primaryColor,
            local_secondary_color: secondaryColor,
            local_background_color: backgroundColor,
            local_body_font_family: bodyFontFamily,
            local_button_font_family: buttonFontFamily,
            local_header_font_family: headerFontFamily,
            business_card_type: businessCardType
        }
        clearLocalParamsCache(local_id)
        const queryParams = {
            local_id,
            data_type: 'local_style'
        }
        const response = await api_request('PUT', `/api/locals/settings`, { queryParams, body })
        setSaving(false)
        onSaved(true)

    }



    function convertFonts(fonts) {
        return fonts.map(font => ({
            label: font,
            value: font,
            style: {
                fontFamily: font
            }
        }));
    }

    return (

        <Box>
            {loading ?
                <LoadingForm />
                :
                <Box>

                    <Grid container spacing={1} style={{}}>
                        <Grid item xs={12}>
                            <Typography style={{ textAlign: 'center' }} variant='h6'>
                                Font Locale
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} >
                            <SelectButton
                                label={'Stile intestazioni'}
                                options={convertFonts(fonts)}
                                value={headerFontFamily}
                                onChange={setHeaderFontFamily}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <SelectButton
                                label={'Stile testo'}
                                options={convertFonts(fonts)}
                                value={bodyFontFamily}
                                onChange={setBodyFontFamily}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <SelectButton
                                label={'Stile pulsanti'}
                                options={convertFonts(fonts)}
                                value={buttonFontFamily}
                                onChange={setButtonFontFamily}
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <Typography style={{ textAlign: 'center' }} variant='h6'>
                                Colori Locale
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <ColorPicker color={primaryColor} setColor={setPrimaryColor} variantColor={'primary'} buttonText={'colore primario'} />

                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>

                            <ColorPicker color={secondaryColor} setColor={setSecondaryColor} variantColor={'secondary'} buttonText={'colore secondario'} />

                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <ColorPicker color={backgroundColor} setColor={setBackgroundColor} variantColor={'primary'} buttonText={'colore sfondo'} />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography style={{ textAlign: 'center' }} variant='h6'>
                                Seleziona lo stile del biglietto da visita che preferisci
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <SelectButton
                                label={undefined}
                                options={[
                                    { label: 'Immagine laterale', value: 'side' }, 
                                    { label: 'Immagine come sfondo', value: 'background' }, 
                                    { label: 'Informazioni verticali', value: 'vertical' },
                                    { label: 'Immagine circolare', value: 'circle' },
                                ]}
                                value={businessCardType}
                                onChange={setBusinessCardType}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Box style={{ backgroundColor: theme.palette.background.default, borderRadius: '5px',minHeight:'75vh' }}>
                                <BusinessCard
                                    type={businessCardType}
                                    image={data.local_cover}
                                    name={data.local_name}
                                    city={data.local_city}
                                    cap={data.local_cap}
                                    address={data.local_address}
                                    phone={data.local_phone}
                                    whatsapp={data.local_whatsapp}
                                    facebook={data.facebook}
                                    site={data.site}
                                    instagram={data.instagram}
                                    isOpen={data.isOpen}
                                />
                            </Box>

                        </Grid>



                    </Grid>








                </Box>
            }
        </Box>


    )
}

