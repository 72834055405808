import React, { useState, useEffect } from "react";
import { Card, Typography, ButtonBase, Box, Stack, Grid } from "@mui/material";
import RenderModal from "../../Modals/RenderModal";
import { useTranslation } from "react-i18next";
import ProductsMenuList from "./ProductsMenuList";
import FilterListIcon from "@mui/icons-material/FilterList";


function GlassEffectCard({
  menuCardShadow = 1,
  section_card_color = "white",
  name,
  section,
  getSubSectionCover,
  onClick,
  productsIdHidden,
  ...rest
}) {
  let totalSectionProducts = 0;
  let countVisibleProducts = 0;
  const [subSectionCover, setSubSectionCover] = useState(null);

  async function getCover() {
    const cover = await getSubSectionCover(section.subSectionId);
    setSubSectionCover(cover);
  }

  useEffect(() => {
    getCover();
  }, []);

  if (productsIdHidden.length > 0) {
    totalSectionProducts = Object.keys(section.subSectionData).length;
    countVisibleProducts = section.subSectionData.filter(
      (item) => !productsIdHidden.includes(item.product_id)
    ).length;
  }

  return (
    <Card
      sx={{
        width: "100%",
        height: "150px",
        position: "relative",
        borderRadius: "20px",
        overflow: "hidden",
        boxShadow: menuCardShadow,
        margin: "0px",
        backgroundColor: section_card_color,
      }}
      {...rest}
    >
      <ButtonBase
        sx={{
          display: "contents",
          borderRadius: "10px",
          backgroundColor: section_card_color,
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            height: "100%",
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage:
                "linear-gradient(to top, rgba(0,0,0,0.8) 30%,  transparent 100%)",
              borderRadius: "20px",
            },
          }}
        >
          
          {subSectionCover && (
            <img
              src={subSectionCover}
              alt={name}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "20px",
              }}
            />
          )}

        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            width: "100%",
            color: "white",
          }}
        >
          {productsIdHidden.length > 0 && (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FilterListIcon />

              <Typography
                variant="h6"
                component="div"
                sx={{ textAlign: "center" }}
              >
                {countVisibleProducts}/{totalSectionProducts}
              </Typography>
            </Stack>
          )}
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ textAlign: "center" }}
          >
            {name}
          </Typography>
        </Box>
      </ButtonBase>
    </Card>
  );
}

const ProductsMenuSubSections = ({
  subSectionsData,
  productsIdHidden,
  isStaff,
  getSubSectionCover,
  basket,
  setBasket,
  section_name,
  section_card_color,
  card_variant_selected,
  card_animation_variant_selected,
  getProductCover,
  responsiveStyle,
  menuCardShadow,
  menuAddProductDialogFullscreen,
  menuAddProductDialogAnimation,
}) => {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const { i18n } = useTranslation();
  const language = i18n.language ? i18n.language.toLowerCase() : "it";

  const handleOpen = (subSection) => {
    setModalContent(subSection);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const sortedSubSections = Object.entries(subSectionsData)
    .map(([subSectionId, content]) => ({ subSectionId, ...content }))
    .sort((a, b) => a.position - b.position);



  let headerSubSectionName = modalContent?.subSectionName?.[language];
  if (section_name) {
    headerSubSectionName = section_name + " / " + headerSubSectionName;
  }

  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {sortedSubSections.map((section, index) => {
          if (
            section.subSectionData.filter(
              (item) => item.product_hidden === false
            ).length > 0
          ) {
            return (
              <Grid key={section.subSectionId} item xs={12} md={6} lg={4} xl={3}>
                <GlassEffectCard
                  style={{ marginBottom: "10px" }}
                  getSubSectionCover={getSubSectionCover}
                  onClick={() => handleOpen(section)}
                  name={section?.subSectionName?.[language]}
                  section={section}
                  productsIdHidden={productsIdHidden}
                />
              </Grid>
            );
          }
          return null; // Ritorna null se la condizione non è soddisfatta
        })}
      </Grid>

      <RenderModal
        open={open}
        setOpen={setOpen}
        fullScreen
        transitionType={menuAddProductDialogAnimation}
        header={headerSubSectionName}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ marginTop: "5px" }}>
          {modalContent?.subSectionData && (
            <Box>
              {modalContent?.subSectionDescription?.[language] && (
                <Box
                  sx={{
                    padding: "5px",
                    backgroundColor: "#f7f7f7",
                    margin: "15px 10px",
                    borderRadius: "5px",
                    boxShadow: 1,
                  }}
                >
                  <Typography color="black" variant="body">
                    {modalContent?.subSectionDescription?.[language]}
                  </Typography>
                </Box>
              )}
              <ProductsMenuList
                basket={basket}
                sectionMenuData={modalContent.subSectionData}
                productsIdHidden={productsIdHidden}
                isStaff={isStaff}
                setBasket={setBasket}
                section_card_color={section_card_color}
                card_variant_selected={card_variant_selected}
                card_animation_variant_selected={
                  card_animation_variant_selected
                }
                getProductCover={getProductCover}
                responsiveStyle={responsiveStyle}
                menuCardShadow={menuCardShadow}
                menuAddProductDialogFullscreen={menuAddProductDialogFullscreen}
                menuAddProductDialogAnimation={menuAddProductDialogAnimation}
              />
            </Box>
          )}
        </Box>
      </RenderModal>
    </div>
  );
};

export default ProductsMenuSubSections;
