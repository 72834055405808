import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

export default function DynamicFontTypography({
  text,
  maxFontSize = 26, // Dimensione massima del font
  minFontSize = 16, // Dimensione minima del font
  maxLength = 22, // Lunghezza massima prima di ridurre il font
  sensitivity = 0.3, // Sensibilità di riduzione del font
  align = "left", // Posizionamento del testo
  variant = "body1", // Variante del testo (es. body1, h1)
  ...rest
}) {
  // Calcola la dimensione del font usando clamp
  const fontSize = useMemo(() => {
    const length = text?.length||0;

    // Riduzione proporzionale alla lunghezza del testo
    const reducedFontSize = Math.max(
      minFontSize,
      maxFontSize - (length - maxLength) * sensitivity
    );

    // Applica clamp con valori dinamici
    return `clamp(${minFontSize}px, ${reducedFontSize}px, ${maxFontSize}px)`;
  }, [text, maxFontSize, minFontSize, maxLength, sensitivity]);

  return (
    <Typography
      variant={variant}
      align={align}
      style={{
        fontSize, // Usando clamp
        transition: "font-size 0.2s ease", // Transizione fluida
      }}
      {...rest}
    >
      {text}
    </Typography>
  );
}

DynamicFontTypography.propTypes = {
  text: PropTypes.string.isRequired,
  maxFontSize: PropTypes.number,
  minFontSize: PropTypes.number,
  maxLength: PropTypes.number,
  sensitivity: PropTypes.number,
  align: PropTypes.oneOf(["left", "center", "right", "justify"]),
  variant: PropTypes.string,
};
