import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ProductCard from "../Cards/ProductCard";
import { ItemAnimation } from "../Animations/ItemAnimation";
import ModalAddProductToBasket from "../Cards/ModalAddProductToBasket";

export default function ProductMenuCard({
  basket,
  setBasket,
  card_animation_variant_selected,
  card_variant_selected,
  product_id,
  product_name,
  product_description,
  product_storytelling,
  product_perfect_service,
  product_price,
  product_cover,
  product_currency,
  product_formats,
  product_custom_text,
  productIngredients,
  productAllergens,
  productCategoriesFood,
  productTag,
  section_card_color,
  menuCardShadow,
  getProductCover,
  menuAddProductDialogAnimation,
  menuAddProductDialogFullscreen,
  productAlcoholicGrades,
}) {
  const { local_id } = useParams();
  const [openModalAddProduct, setOpenModalAddProduct] = useState(false);
  const [productCoverObj, setProductCoverObj] = useState(product_cover);
  const { i18n } = useTranslation();
  const language = i18n.language ? i18n.language.toLowerCase() : "it";

  async function eventListen() {
    setOpenModalAddProduct(false);
  }

  useEffect(() => {
    async function fetchProductCover() {
      if (
        product_cover === undefined ||
        product_cover === null ||
        product_cover === ""
      ) {
        const cover = await getProductCover(product_id);
        setProductCoverObj(cover);
      }
    }
    fetchProductCover();
  }, []);

  function openModal() {
    setOpenModalAddProduct(true);
    window.history.pushState("fake-route", document.title, "");
    window.addEventListener("popstate", (event) => {
      eventListen();
    });
  }

  let product_name_lng = product_name[language]
    ? product_name[language]
    : product_name.it;
  let product_description_lng = product_description[language]
    ? product_description[language]
    : product_description.it;
  let product_storytelling_lng = product_storytelling?.[language]
    ? product_storytelling[language]
    : product_storytelling?.it;
  let product_perfect_service_lng = product_perfect_service?.[language]
    ? product_perfect_service[language]
    : product_perfect_service?.it;

  return (
    <ItemAnimation
      variant={card_animation_variant_selected}
      animateInView
      duration={2}
      bounce={0}
      oneTime
      delay={0.25}
      style={{ placeContent: "center", padding: "0px", width: "100%" }}
    >
      <ModalAddProductToBasket
        basket={basket}
        setBasket={setBasket}
        productTag={productTag}
        open={openModalAddProduct}
        setOpen={setOpenModalAddProduct}
        local_id={local_id}
        product_name={product_name_lng}
        product_cover={productCoverObj}
        product_id={product_id}
        product_description={product_description_lng}
        product_storytelling={product_storytelling_lng}
        product_perfect_service={product_perfect_service_lng}
        product_price={product_price}
        product_formats={product_formats}
        product_custom_text={product_custom_text}
        product_currency={product_currency}
        productIngredients={productIngredients || {}}
        productAllergens={productAllergens}
        productCategoriesFood={productCategoriesFood}
        transitionType={menuAddProductDialogAnimation}
        fullScreen={menuAddProductDialogFullscreen}
        productAlcoholicGrades={productAlcoholicGrades}
      />
      <ProductCard
        variant={card_variant_selected}
        onClick={() => openModal()}
        product_name={product_name_lng}
        product_cover={productCoverObj}
        section_card_color={section_card_color}
        product_description={product_description_lng}
        product_storytelling={product_storytelling_lng}
        product_perfect_service={product_perfect_service_lng}
        product_price={product_price}
        product_currency={product_currency}
        productAllergens={productAllergens}
        productCategoriesFood={productCategoriesFood}
        menuCardShadow={menuCardShadow}
        productAlcoholicGrades={productAlcoholicGrades}
      />
    </ItemAnimation>
  );
}

ProductMenuCard.propTypes = {
  setOpen: PropTypes.func,
  setBasket: PropTypes.func,
  getProductCover: PropTypes.func,
};
