import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";

import { useTheme } from "@mui/material/styles";

import {
  Button,
  Typography,
  IconButton,
  Stack,
  Grid,
  Alert,
  Box,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import SelectAlternativeIngredient from "../Products/SelectAlternativeIngredient";
import CircularProgress from "@mui/material/CircularProgress";

import ProductImage from "../Cards/ProductImage";
import RenderIngredientOption from "../Products/RenderIngredientOption";
import { RenderIcon } from "../Cards/RenderIcon";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { GetTransitionComponent } from "../Animations/DialogTransitions";
import ProductsFormatSelector from "../Products/ProductFormatSelector";
import { MarkDownVisualizer } from "../Forms/MarkDownEditor";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Trans } from "react-i18next";

export default function ModalAddProductToBasket({
  basket,
  setBasket,
  transitionType,
  open,
  setOpen,
  fullScreen,
  local_id,
  product_name,
  product_cover,
  product_id,
  product_description,
  product_storytelling,
  product_perfect_service,
  product_price,
  product_currency,
  productTag,
  product_formats,
  productIngredients,
  productAllergens,
  productCategoriesFood,
  productAlcoholicGrades,
  product_custom_text,
}) {
  const theme = useTheme();
  const [product_message, setProductMessage] = useState("");
  const [product_priceVar, setProductPriceVar] = useState(undefined);
  let [productIngredientsVar, setProductIngredientsVar] = useState({});
  const [formatSelected, setFormatSelected] = useState([]);

  const [adding, setAdding] = useState(false);

  const addToBasket = () => {
    // Creare un copia del carrello per evitare la modifica diretta dell'array originale
    const updatedBasket = [...(basket[local_id] || [])];
    // Verificare se il prodotto esiste già nel carrello
    const existingProductIndex = updatedBasket.findIndex(
      (basketProduct) =>
        basketProduct.product_id === product_id &&
        JSON.stringify(basketProduct.productIngredientsVar) ===
          JSON.stringify(productIngredientsVar) &&
        basketProduct.product_message === product_message &&
        JSON.stringify(basketProduct.productFormatSelected) ===
          JSON.stringify(formatSelected)
    );

    if (existingProductIndex >= 0) {
      // Il prodotto esiste già nel carrello, quindi incrementare la quantità
      updatedBasket[existingProductIndex].quantity += 1;
    } else {
      // Il prodotto non esiste nel carrello, quindi aggiungerlo
      updatedBasket.push({
        product_id: product_id,
        product_name: product_name,
        product_price: product_priceVar,
        product_currency: product_currency,
        product_message: product_message || "",
        quantity: 1,
        productIngredientsVar: { ...productIngredientsVar },
        productFormatSelected: formatSelected,
      });
    }

    // Aggiorna solo l'array di prodotti per il local_id specifico
    setBasket((prevBasket) => {
      return {
        ...prevBasket,
        [local_id]: updatedBasket,
      };
    });
    setOpen(false);
    navigator.vibrate(50);
    return updatedBasket;
  };

  useEffect(() => {
    setAdding(false); // blocca il pulsante aggiungi
    setProductIngredientsVar({}); //<- viene resettato ogni volta che viene aperta o chiusa la modale
  }, [open]);

  useEffect(() => {
    // viene aggiornato il prezzo totale del prodotto dopo la selezione degli ingredienti
    function calculateTotalPrice(product_price, ingredients) {
      let priceVariationSum = 0;

      for (const category in ingredients) {
        for (const ingredient in ingredients[category]) {
          let quantity = ingredients[category][ingredient]["quantity"];
          let priceVariation = parseFloat(
            ingredients[category][ingredient]["priceVariation"]
          );
          priceVariationSum += priceVariation;
        }
      }

      return product_price + priceVariationSum;
    }
    let temp_price_var = 0;
    console.log("formatSelected:", formatSelected);
    if (formatSelected.price) {
      console.log("formatSelected.price:", formatSelected.price);
      temp_price_var = calculateTotalPrice(
        formatSelected.price,
        productIngredientsVar
      );
    } else if (product_formats?.length > 0) {
      temp_price_var = calculateTotalPrice(
        product_formats[0].price,
        productIngredientsVar
      );
    } else {
      temp_price_var = calculateTotalPrice(
        product_price,
        productIngredientsVar
      );
    }
    setProductPriceVar(temp_price_var);
  }, [productIngredientsVar, formatSelected]);

  function RenderAllergensCategories({ array, title }) {
    if (array === null) {
      return null;
    } else {
      const arrayParsed = array;
      if (arrayParsed.length == 0) {
        return null;
      }

      return (
        <Stack
          direction="row"
          spacing={0}
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontWeight: "bold",
              color: theme.palette.secondary.dark,
              marginRight: "5px",
            }}
            variant="body"
            component="body"
          >
            <Trans>{title}</Trans>:
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            useFlexGap
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
          >
            {arrayParsed.map((itemName, indexItem) => (
              <div
                key={itemName + "-" + indexItem}
                id={`button-${title}-${itemName}`}
              >
                <RenderIcon
                  iconName={itemName}
                  backgroundColor={
                    title === "Allergeni"
                      ? theme.palette.primary.dark
                      : theme.palette.secondary.dark
                  }
                  color={"white"}
                  showText={false}
                />
              </div>
            ))}
          </Stack>
        </Stack>
      );
    }
  }

  const handleOnChangeProductIngredientsVar = (category, values) => {
    setProductIngredientsVar((prevState) => {
      // Creiamo una copia dello stato attuale
      const newState = { ...prevState };
      // Apportiamo le modifiche alla copia
      newState[category] = values;
      // Impostiamo lo stato sulla copia modificata
      return newState;
    });
  };

  function ProductCustomText({ product_custom_text }) {
    const isSmallScreen = useMediaQuery("(max-width:960px)"); // Sotto il breakpoint 'md'
    const isTextLong = product_custom_text?.length > 500; // Testo troppo lungo?

    return (
      <>
        {product_custom_text && (
          <>
            {/* Caso: Schermo piccolo e testo lungo */}
            {isSmallScreen || isTextLong ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="additional-info-content"
                  id="additional-info-header"
                >
                  <Typography
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <InfoOutlinedIcon fontSize="small" />
                    Informazioni aggiuntive
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MarkDownVisualizer textAlign={'left'} value={product_custom_text} />
                </AccordionDetails>
              </Accordion>
            ) : (
              // Caso: Testo breve o schermo grande
              <MarkDownVisualizer  textAlign={'left'} value={product_custom_text} />
            )}
          </>
        )}
      </>
    );
  }

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      style={{ zIndex: 1302 }}
      onClose={() => setOpen(false)}
      maxWidth="lg"
      aria-labelledby={"dialog-product-" + product_id}
      TransitionComponent={GetTransitionComponent(transitionType)}
      transitionDuration={open ? 500 : 300}
    >
      <DialogTitle
        sx={{
          padding: "5px",
          background: theme.palette.primary.dark,
          color: "white",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={0}
        >
          <Typography variant="h5">{product_name}</Typography>

          <IconButton
            id="close-modal-add-product-button"
            onClick={() => setOpen(false)}
            style={{
              width: "35px",
              height: "35px",
              padding: "5px",
              background: "#82828226",
            }}
          >
            <CloseRoundedIcon style={{ color: "white" }} />
          </IconButton>
        </Stack>

        {productIngredientsVar["Extra"] &&
          Object.keys(productIngredientsVar["Extra"]).length > 0 && (
            <Alert severity="warning">
              <Trans>
                L'aggiunta di ingredienti extra potrebbe comportare allergeni
                non previsti tra gli ingredienti base
              </Trans>
            </Alert>
          )}
      </DialogTitle>
      <DialogContent sx={{ padding: "0px" }}>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          {product_cover && (
            <Grid item xs={12} style={{ position: "relative" }}>
              <ProductImage
                local_id={local_id}
                product_name={product_name}
                product_cover={product_cover}
                style={{
                  maxHeight: "60vh",
                  maxWidth: "100%",
                  objectFit: "contain",
                  width: "100%", // Aggiunge adattabilità
                }}
              />
            </Grid>
          )}
          {productTag && (
            <Grid item>
              <Box
                sx={{
                  boxShadow: 3,
                  backgroundColor: theme.palette.primary.dark,
                  borderRadius: "10px",
                  padding: "5px",
                  margin: "5px",
                  width: "fit-content",
                }}
              >
                <Typography variant="subtitle1" color="white">
                  {productTag}
                </Typography>
              </Box>
            </Grid>
          )}
          {productAlcoholicGrades > 0 && (
            <Grid item>
              <Box
                sx={{
                  boxShadow: 3,
                  backgroundColor: theme.palette.primary.dark,
                  borderRadius: "10px",
                  padding: "5px",
                  margin: "5px",
                  width: "fit-content",
                }}
              >
                <Typography variant="subtitle1" color="white">
                  {productAlcoholicGrades}°
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item>
            <Box sx={{ padding: "15px" }}>
              <Typography
                variant="subtitle1"
                color="text.paper"
                component="div"
              >
                {product_description}
              </Typography>
              {product_storytelling != "" && product_storytelling != null && (
                <Box
                  sx={{
                    boxShadow: 1,
                    backgroundColor: "#f5f5f5",
                    borderRadius: "5px",
                    padding: "5px",
                    margin: "10px 0px 10px 0px",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="text.paper"
                    component="div"
                  >
                    {product_storytelling}
                  </Typography>
                </Box>
              )}

              {product_custom_text && (
                <ProductCustomText product_custom_text={product_custom_text} />
              )}
              {product_perfect_service != "" &&
                product_perfect_service != null && (
                  <Typography
                    variant="subtitle1"
                    color="text.paper"
                    component="div"
                  >
                    <b>
                      <Trans>Servizio</Trans>:
                    </b>{" "}
                    {product_perfect_service}
                  </Typography>
                )}
            </Box>
          </Grid>
          <Grid item>
            {product_formats?.length > 0 && (
              <ProductsFormatSelector
                initialChips={product_formats}
                selectedChip={product_formats[0]}
                onChipSelect={(values) => setFormatSelected(values)}
              />
            )}
            {productAllergens?.length > 0 && (
              <RenderAllergensCategories
                title={"Allergeni"}
                array={productAllergens}
              />
            )}
            {productCategoriesFood?.length > 0 && (
              <RenderAllergensCategories
                title={"Categorie"}
                array={productCategoriesFood}
              />
            )}
          </Grid>
        </Grid>
        <Box>
          {productIngredients["Alternative"] &&
            Object.keys(productIngredients["Alternative"]).length > 0 && (
              <SelectAlternativeIngredient
                ingredientOption={"Alternative"}
                productIngredientsDefault={productIngredients["Alternative"]}
                product_currency={"€"}
                onChange={(values) =>
                  handleOnChangeProductIngredientsVar("Alternative", values)
                }
              />
            )}

          {productIngredients["Base"] &&
            Object.keys(productIngredients["Base"]).length > 0 && (
              <RenderIngredientOption
                ingredientOption={"Base"}
                productIngredientsDefault={productIngredients["Base"]}
                product_currency={"€"}
                onChange={(values) =>
                  handleOnChangeProductIngredientsVar("Base", values)
                }
              />
            )}

          {productIngredients["Extra"] &&
            Object.keys(productIngredients["Extra"]).length > 0 && (
              <RenderIngredientOption
                ingredientOption={"Extra"}
                productIngredientsDefault={productIngredients["Extra"]}
                product_currency={"€"}
                onChange={(values) =>
                  handleOnChangeProductIngredientsVar("Extra", values)
                }
              />
            )}

          <TextField
            multiline
            autoComplete="off"
            style={{ width: "-webkit-fill-available", margin: "5px" }}
            error={false}
            id="standard-basic"
            label={<Trans>Messaggio</Trans>}
            variant="standard"
            value={product_message}
            onChange={(event) => setProductMessage(event.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "5px",
          display: "inline",
          background: theme.palette.primary.dark,
          color: "white",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Typography
              variant="h6"
              style={{ whiteSpace: "normal", color: "white" }}
            >
              <Trans>Prezzo</Trans>:
            </Typography>
            <Typography
              variant="h6"
              style={{
                whiteSpace: "nowrap",
                color: "white",
                marginLeft: "10px",
              }}
              id="product-price-value"
            >
              {parseFloat(product_priceVar).toFixed(2)}
            </Typography>
            <Typography
              variant="h6"
              style={{
                whiteSpace: "nowrap",
                color: "white",
                marginLeft: "2px",
              }}
              id="product-price-currency"
            >
              €
            </Typography>
          </div>

          <Button
            id="button-add-product-to-basket"
            startIcon={
              adding ? (
                <CircularProgress style={{ height: "15px", width: "15px" }} />
              ) : (
                <AddCircleIcon />
              )
            }
            disabled={adding}
            color="secondary"
            variant="contained"
            onClick={() => (setAdding(true), addToBasket())}
          >
            <Trans>Aggiungi</Trans>
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

ModalAddProductToBasket.propTypes = {
  setOpen: PropTypes.func,
  setBasket: PropTypes.func,
  fullScreen: PropTypes.bool,
};
