import { Box, Typography, Card, Grid } from "@mui/material";
import dayjs from "dayjs";
import { Trans } from "react-i18next";

function Shift({ shift }) {
  return (
    <Typography>
      {dayjs(shift.start).format("HH:mm")} - {dayjs(shift.end).format("HH:mm")}
    </Typography>
  );
}

function DayScheduleDisplay({ day, shifts,...rest }) {
  return (
    <Card  style={{ display: 'flex', flexDirection: 'column', height: '100%' }} sx={{ margin: "5px", padding: "5px", textAlignLast: 'center' }} {...rest} >
      <Typography variant="h6"><Trans>{day.replace(/ì/g, "i")}</Trans></Typography>
      {shifts.length > 0 ? (
        <>
          {shifts.map((shift, index) => (
            <Shift key={index} shift={shift} />
          ))}
        </>
      ) : (
        <div>
          <Typography variant="body">Chiuso</Typography>
        </div>
      )}
    </Card>
  );
}

export default function OepnTimesDisplay({ schedules = {},...rest }) {
  const days = [
    "Lunedì",
    "Martedì",
    "Mercoledì",
    "Giovedì",
    "Venerdì",
    "Sabato",
    "Domenica"
  ];

  return (
    <Grid container spacing={1} display="flex" alignItems="stretch" justifyContent="center">
      {days.map((day) => (
        <Grid key={day} item xs={12} sm={6} md={3} lg={4} xl={1.7} display="flex" flexDirection="column" alignItems="stretch">
          <DayScheduleDisplay day={day} shifts={schedules[day] || []} {...rest}/>
        </Grid>
      ))}
    </Grid>

  );
}
