import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Typography, Box, Tab, Tabs, AppBar } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { useTheme } from "@mui/material/styles";
import ProductsMenuSection from "./ProductsMenuSection";

import { getContrastColors, hexToRgba } from "../../Functions/Colors";
import { RenderParallaxImage } from "../../Animations/UseParallaxScroll";
import {
  calculateVisibilityCounts,
  filterSectionsWithAvailableElements,
} from "./MenuFunctions";


export default function MenuTabsScroll({
  basket,
  setBasket,
  getSectionCover,
  getSubSectionCover = () => {},
  getProductCover,
  section_names = {},
  sectionsOrders = [],
  productsIdHidden = [],
  menuData = {},
  isStaff = false,
  card_variant_selected = 'default',
  card_animation_variant_selected,
  menuCardShadow,
  menuAddProductDialogFullscreen,
  menuAddProductDialogAnimation,
  menuVariant
}) {
  const sectionRefs = useRef([]);

  useEffect(() => {
    setManualScroll(true); // Abilitare lo scroll manuale all'inizio
  }, []);

  const handleChangeTab = (event, newValue) => {
    setManualScroll(true); // Indicare che lo scroll è iniziato manualmente
    setTabValue(newValue);
    if (sectionRefs.current[newValue]) {
      const sectionHeight = sectionRefs.current[newValue].offsetTop;
      console.log('scrolling')
      window.scrollTo({
        top: sectionHeight ? sectionHeight - 50 : 0,
        behavior: "smooth",
      });
    }
    setManualScroll(false);
    setTimeout(() => {
      setManualScroll(true); // Riabilitare lo scroll manuale dopo 1 secondo
    }, 1000);
  };

  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const [responsiveStyle, setResponsiveStyle] = useState({
    xs: 12,
    sm: 12,
    md: 6,
    lg: 4,
    xl: 3,
  });

  useEffect(() => {
    if (
      (card_variant_selected === "image") |
      (card_variant_selected === "glass-effect")
    ) {
      setResponsiveStyle({ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 });
    } else {
      setResponsiveStyle({ xs: 12, sm: 12, md: 6, lg: 4, xl: 3 });
    }
  }, [card_variant_selected]);

  // ... (codice precedente)
  const [manualScroll, setManualScroll] = useState(false); // Definisci una sola volta
  const [sectionHeights, setSectionHeights] = useState({});

  // Calcola l'altezza delle sezioni
  useEffect(() => {
    // Funzione per aggiornare le altezze delle sezioni
    const updateSectionHeights = () => {
      const newSectionHeights = {};
      sectionRefs.current.forEach((ref, index) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          newSectionHeights[index] = {
            top: window.scrollY + rect.top,
            bottom: window.scrollY + rect.bottom,
          };
        }
      });
      setSectionHeights(newSectionHeights);
    };

    // Aggiorna le altezze una volta all'inizio
    updateSectionHeights();

    // Imposta un intervallo per aggiornare periodicamente
    let timer = setInterval(updateSectionHeights, 3000);

    // Imposta un listener per l'evento resize
    const handleResize = () => {
      clearTimeout(timer); // Pulisce il timer corrente
      updateSectionHeights(); // Aggiorna immediatamente le altezze
      timer = setInterval(updateSectionHeights, 3000); // Ripristina l'intervallo
    };

    window.addEventListener("resize", handleResize);

    // Pulizia: Rimuove il timer e il listener se il componente viene smontato
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [sectionsOrders, menuData, section_names]);

  const handleManualScroll = () => {
    const currentScroll = window.scrollY;
    const windowHeight = window.innerHeight;

    let mostVisibleSection = null;
    let maxVisibility = 0;

    Object.keys(sectionHeights).forEach((key) => {
      const { top, bottom } = sectionHeights[key];

      const visibleTop = Math.max(top, currentScroll);
      const visibleBottom = Math.min(bottom, currentScroll + windowHeight);
      const visibleHeight = Math.max(0, visibleBottom - visibleTop);

      if (visibleHeight > maxVisibility) {
        mostVisibleSection = key;
        maxVisibility = visibleHeight;
      }
    });

    if (mostVisibleSection !== null) {
      setTabValue(parseInt(mostVisibleSection));
    }
  };

  useEffect(() => {
    if (manualScroll) {
      window.addEventListener("scroll", handleManualScroll);
      return () => window.removeEventListener("scroll", handleManualScroll);
    }
  }, [manualScroll, sectionHeights]);

  let [sectionsCover, setSectionsCover] = useState({});
  useEffect(() => {
    const fetchCovers = async () => {
      const covers = {};

      for (const section_id of sectionsOrders) {
        const cover = await getSectionCover(section_id);
        covers[section_id] = { sectionCover: cover };
      }

      setSectionsCover(covers);
      console.log("covers:", covers);
    };

    fetchCovers();
  }, [sectionsOrders]);

  const [sectionsOrdersFiltered, setSectionsOrdersFiltered] = useState([]);
  useEffect(() => {
    const filteredSectionsOrders = filterSectionsWithAvailableElements(
      sectionsOrders,
      menuData,
      productsIdHidden
    );
    setSectionsOrdersFiltered(filteredSectionsOrders);
  }, [sectionsOrders]);

  const tabsRef = useRef(null);
  const [tabsHeight, setTabsHeight] = useState(35);

  useEffect(() => {
    if (tabsRef.current) {
      setTabsHeight(tabsRef.current.offsetHeight);
    }
  }, [tabValue, sectionsOrdersFiltered]);

  const indicatorColor = theme.palette.getContrastText(
    theme.palette.primary.dark
  );

  const AntTabs = {
    "& .MuiTabs-indicator": {
      height: tabsHeight - 10 + "px",
      marginBottom: "5px",
      backgroundColor: indicatorColor,
      opacity: "20%",
      borderRadius: "20px",
      transition: "0.5s ease-in-out",
    },
    "&.Mui-selected": {
      textColor: "black", // Modifica questo con il codice colore desiderato
    },
  };



  return (
    <Box>
        <TabContext value={tabValue.toString()}>
          <AppBar
            position="static"
            style={{ position: "sticky", top: 0, zIndex: 10 }}
          >
            <Tabs
              ref={tabsRef}
              value={tabValue}
              sx={{ ...AntTabs }}
              onChange={handleChangeTab}
              indicatorColor="secondary"
              textColor="inherit"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable tabs local menu"
            >
              {sectionsOrdersFiltered.map((section_id, index) => {
                const { elementsShowed, elementsAvaiable } =
                  calculateVisibilityCounts(
                    menuData,
                    section_id,
                    productsIdHidden
                  );
                const section_name = section_names[section_id].section_name;
                return (
                  <Tab
                    key={"section_id-" + section_id}
                    sx={{
                      maxWidth: "50vw",
                      display: !elementsAvaiable && "none",
                      color: tabValue === index ? indicatorColor : "inherit",
                      fontWeight: tabValue === index && "bold",
                    }}
                    label={section_name}
                  />
                );
              })}
            </Tabs>
          </AppBar>
        </TabContext>
      {sectionsOrdersFiltered.map((section_id, index) => {
        const section_values = section_names[section_id];
        const section_cover = sectionsCover[section_id]?.sectionCover;
        const section_name = section_values.section_name;
        const section_color = section_values.section_color;
        const section_card_color = section_values.section_card_color;
        const section_color_contrast = section_color
          ? getContrastColors("#ffffff", section_color)
          : undefined;
        const { elementsShowed, elementsAvaiable } = calculateVisibilityCounts(
          menuData,
          section_id,
          productsIdHidden
        );
        if (elementsAvaiable > 0) {
          return (
            <Box
              sx={{
                padding: "0px",
                backgroundColor: section_color
                  ? section_color
                  : theme.palette.background.default,
                borderRadius: "0px",
                borderTop: "1px solid #a3a3a370",
              }}
              key={"tab-panel-" + section_id}
              ref={(el) => (sectionRefs.current[index] = el)}
            >
              {section_cover ? (
                <RenderParallaxImage
                  url={section_cover}
                  speed={0}
                  style={{ height: "300px" }}
                >
                  <Typography
                    variant="h4"
                    align="center"
                    color="BLACK"
                    style={{
                      position: "absolute", // Posizionato assolutamente all'interno del contenitore
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: section_color_contrast,
                      borderColor: section_color,
                      border: "solid 0px",
                      background: section_color
                        ? hexToRgba(section_color, 0.75)
                        : "rgb(255 255 255 / 75%)", // Sfondo semi-trasparente
                      padding: "0.5em",
                      borderRadius: "10px",
                      minWidth: "50%",
                    }}
                  >
                    {section_name}
                  </Typography>
                </RenderParallaxImage>
              ) : (
                <Box
                  sx={{
                    padding: "5px",
                    color: section_color
                      ? getContrastColors("#ffffff", section_color)
                      : "black",
                    border: `solid 0px  ${
                      section_color ? section_color : "white"
                    }`,
                    background: section_color ? section_color : "white", // Sfondo semi-trasparente
                    borderRadius: "0px",
                  }}
                >
                  <Typography variant="h6" align="center">
                    {section_name}
                  </Typography>
                </Box>
              )}

              <Box sx={{ padding: "5px" }}>
                <ProductsMenuSection
                  sectionMenuData={menuData[section_id]}
                  section_description={section_values.section_description}
                  section_id={section_id}
                  productsIdHidden={productsIdHidden}
                  getSubSectionCover={getSubSectionCover}
                  isStaff={isStaff}
                  basket={basket}
                  setBasket={setBasket}
                  section_color_contrast={section_color_contrast}
                  section_card_color={section_card_color}
                  section_color={section_color}
                  section_name={section_name}
                  section_cover={section_values.section_cover}
                  card_variant_selected={card_variant_selected}
                  card_animation_variant_selected={
                    card_animation_variant_selected
                  }
                  getProductCover={getProductCover}
                  responsiveStyle={responsiveStyle}
                  menuCardShadow={menuCardShadow}
                  menuAddProductDialogFullscreen={
                    menuAddProductDialogFullscreen
                  }
                  menuAddProductDialogAnimation={menuAddProductDialogAnimation}
                />
              </Box>
            </Box>
          );
        }
      })}
    </Box>
  );
}

MenuTabsScroll.propTypes = {
  sectionsOrders: PropTypes.array,
  section_names: PropTypes.object,
  productsIdHidden: PropTypes.array,
  menuData: PropTypes.object,
  isStaff: PropTypes.bool,
  menuDataIngredients: PropTypes.object,
  card_variant_selected: PropTypes.string,
  getProductCover: PropTypes.func,
};

