import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  lazy,
} from "react";
import { createTheme } from "@mui/material/styles";
import { jwtDecode as jwt_decode } from "jwt-decode";

import { RenderModal, getContrastColors } from "../story-components/components";
import pubSubService from "./PubSubService";
import { Typography, Box } from "@mui/material";
import LoginButton from "../components/Login/LoginButton";

//creazione del context a cui viene aggiunto il valore dal provider
export const UserContext = createContext({});
var metaThemeColor = document.querySelector("meta[name=theme-color]");
import { FaviconUpdater } from "./FaviIconUpdater";


//Creazione del provider da inserire come padre per poi ottenere il context nei componenti figli
const importFont = (fontName) => import(`./fonts/${fontName}.css`);

const loadFonts = async (fonts) => {
  try {
    await Promise.all(fonts.map((font) => importFont(font)));
    console.log("All fonts loaded successfully");
  } catch (error) {}
};

import { fonts } from "../pages/Locals/Local/EditLocal/components/LocalFontsAndColors";

// Esempio di uso
loadFonts(fonts);

export const UserContextProvider = ({ children }) => {
  function getSessionObject(name, objectType) {
    const value = localStorage.getItem(name);
    if (value === null) {
      return objectType;
    } else {
      try {
        return JSON.parse(value);
      } catch {
        return objectType;
      }
    }
  }

  const [userId, setUserId] = useState(undefined);
  const [user, setUser] = useState(localStorage.getItem("user"));
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [localData, setLocalData] = useState({});
  const [referrerLocalId, setReferrerLocalId] = useState(undefined);

  const [userPromotions, setUserPromotions] = useState([]);
  const [userConsents, setUserConsents] = useState([]);
  let [user_licenses, setUserLicenses] = useState(
    getSessionObject("user_licenses", [])
  );

  const [openBackDrop, setOpenBackDrop] = useState(localStorage.getItem(false));
  const [customerView, setCustomerView] = useState(false);
  const [managerViewState, setManagerViewState] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  function getSessionData(name, objectType) {
    const data = localStorage.getItem(name);
    if (data === null) {
      return objectType;
    } else {
      return JSON.parse(data);
    }
  }

  const [token, setToken] = useState(localStorage.getItem("token"));
  const [userObject, setUserObject] = useState(
    getSessionData("userObject", {})
  );
  const [logged, setLogged] = useState(false);
  const [userNotifications, setUserNotifications] = useState({});
  const [localSubscriptions, setLocalSubscriptions] = useState([]);

  // USER INFORMATION //

  function IsAllowed(local_id, levelsRequired) {
    if (customerView === true) {
      return false;
    }
    //controllo admin
    if (user_licenses !== null && user_licenses) {
      const filterAdmin = user_licenses.filter(
        (value) => (value[0] === null) & ["ADMIN"].includes(value[1])
      );
      if (filterAdmin.length > 0) {
        return true;
      }
      if (local_id !== undefined) {
        const filterLicenses = user_licenses.filter(
          (value) => levelsRequired.includes(value[1]) && local_id == value[0]
        );
        if (filterLicenses.length > 0) {
          return true;
        }
      }
    }
    if (
      user_licenses !== null &&
      levelsRequired != undefined &&
      user_licenses
    ) {
      const filterLicense = user_licenses.filter(
        (value) => (value[0] === local_id) & levelsRequired.includes(value[1])
      );
      if (filterLicense.length > 0) {
        return true;
      }
    }
    return false;
  }

  function getStoredColor(item, standardColor) {
    const tempColor = localStorage.getItem(item);

    if (
      tempColor === null ||
      tempColor === "undefined" ||
      tempColor === undefined
    ) {
      if (standardColor === undefined) {
        return "#ae0000";
      } else {
        return standardColor;
      }
    } else {
      return tempColor;
    }
  }

  const backgroundDefaultColor = "#ffffff";
  const primaryDefaultColor = "#1f2531";
  const secondaryDefaultColor = "#7C7883";
  const primaryTextDefaultColor = "#393E4B";
  const secondaryTextDefaultColor = "#61544A";
  const headerDefaultFontFamily = "Kalam";
  const buttonDefaultFontFamily = "Georgia";
  const bodyDefaultFontFamily = "Georgia";
  const defaultFontFamily = "Georgia";

  //----- theming -------//
  const [backgroundColor, setBackgroundColor] = useState(
    getStoredColor("backgroundColor", backgroundDefaultColor)
  );
  const [paperColor, setPaperColor] = useState("#ffffff");

  const [primaryColor, setPrimaryColor] = useState(
    getStoredColor("primaryColor", primaryDefaultColor)
  );
  const [secondaryColor, setSecondaryColor] = useState(
    getStoredColor("secondaryColor", secondaryDefaultColor)
  );

  const [headerFontFamily, setHeaderFontFamily] = useState(
    getStoredColor("headerFontFamily", headerDefaultFontFamily)
  );
  const [buttonFontFamily, setButtonFontFamily] = useState(
    getStoredColor("buttonFontFamily", buttonDefaultFontFamily)
  );
  const [bodyFontFamily, setBodyFontFamily] = useState(
    getStoredColor("bodyFontFamily", bodyDefaultFontFamily)
  );
  const [fontFamily, setFontFamily] = useState(
    getStoredColor("fontFamily", defaultFontFamily)
  );

  const [fontSize, setFontSize] = useState(14);

  const breakpoints = {
    values: {
      xs: 0,
      sm: 300,
      md: 600,
      lg: 900,
      xl: 1200,
      xxl: 1536,
    },
  };

  let [dataTheme, setDataTheme] = useState({
    palette: {
      type: "light",
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      background: {
        default: backgroundColor,
        paper: paperColor,
        contrastDefault: "#000000",
        contrastPaper: "#000000",
        accordion: "#fcfcfc",
      },
      backgroundText: {
        default: primaryColor,
        paper: primaryColor,
      },
      text: {
        background: "#fdbe02",
      },
    },
    typography: {
      h1: {
        fontFamily: headerFontFamily,
        //fontSize: parseInt(fontSize) + 20 + 'px',
      },
      h2: {
        fontFamily: headerFontFamily,
        //fontSize: parseInt(fontSize) + 15 + 'px',
      },
      h3: {
        fontFamily: headerFontFamily,
        //fontSize: parseInt(fontSize) + 10 + 'px',
      },
      h4: {
        fontFamily: headerFontFamily,
        //fontSize: parseInt(fontSize) + 5 + 'px',
      },
      h5: {
        fontFamily: headerFontFamily,
        //fontSize: parseInt(fontSize) + 3 + 'px',
      },
      h6: {
        fontFamily: headerFontFamily,
        //fontSize: parseInt(fontSize) + 2 + 'px',
      },
      h7: {
        fontFamily: headerFontFamily,
        //fontSize: parseInt(fontSize) + 1 + 'px',
      },
      subtitle1: {
        fontFamily: bodyFontFamily,
        //fontSize: parseInt(fontSize) + 0 + 'px',
      },
      subtitle2: {
        fontFamily: bodyFontFamily,
        //fontSize: parseInt(fontSize) - 1 + 'px',
      },
      body: {
        fontFamily: bodyFontFamily,
        //fontSize: parseInt(fontSize) + 0 + 'px',
      },
      body1: {
        fontFamily: bodyFontFamily,
        //fontSize: parseInt(fontSize) - 1 + 'px',
      },
      body2: {
        fontFamily: bodyFontFamily,
        //fontSize: parseInt(fontSize) - 2 + 'px',
      },
      button: {
        fontFamily: buttonFontFamily,
        //fontSize: parseInt(fontSize) - 2 + 'px',
      },
      caption: {
        fontFamily: bodyFontFamily,
      },
      fontFamily: bodyFontFamily,
    },
    breakpoints: breakpoints,
  });

  let [outerTheme, setOuterTheme] = useState(createTheme(dataTheme));

  const defaultTheme = {
    palette: {
      type: "light",
      primary: {
        main: primaryDefaultColor,
      },
      secondary: {
        main: secondaryDefaultColor,
      },
      text: {
        primary: primaryTextDefaultColor,
        secondary: secondaryTextDefaultColor,
        hint: "#7f6e85",
        disabled: "#7f6e85",
        background: getContrastColors("#ffffff", backgroundDefaultColor, 0.85),
      },
      background: {
        default: backgroundDefaultColor,
        paper: "#ffffff",
        accordion: "#fcfcfc",
      },
      error: {
        main: "#981a11",
      },
      warning: {
        main: "#de8808",
      },
      info: {
        main: "#087d9e",
      },
      success: {
        main: "#1fb77d",
      },
      divider: "#f3f1f3",
    },
    typography: {
      h2: {
        fontFamily: headerDefaultFontFamily,
      },
      h1: {
        fontFamily: headerDefaultFontFamily,
      },
      h4: {
        fontFamily: headerDefaultFontFamily,
      },
      h3: {
        fontFamily: headerDefaultFontFamily,
      },
      h5: {
        fontFamily: headerDefaultFontFamily,
      },
      h6: {
        fontFamily: headerDefaultFontFamily,
      },
      button: {
        fontFamily: buttonDefaultFontFamily,
      },

      subtitle: {
        fontFamily: bodyDefaultFontFamily,
      },
      body: {
        fontFamily: bodyDefaultFontFamily,
      },
      //fontSize: 10,
      fontFamily: defaultFontFamily,
    },
    breakpoints: breakpoints,
  };

  async function setDefaultTheme() {
    dataTheme = { ...createTheme({ ...defaultTheme }) };
    outerTheme = { ...dataTheme };
    document.body.style = `background: ${backgroundDefaultColor};`;
    metaThemeColor.setAttribute("content", primaryDefaultColor);
    const newTheme = createTheme(dataTheme);
    setOuterTheme({ ...newTheme });
    FaviconUpdater();
  }

  function setThemeColors() {
    metaThemeColor.setAttribute("content", primaryColor);
    document.body.style = `background: ${backgroundColor};`;
  }

  useEffect(() => {
    setThemeColors();
  }, [primaryColor, backgroundColor, localData]);

  //metaThemeColor.setAttribute("content", primaryColor);
  //document.body.style = `background: ${backgroundColor};`

  function fetchOutherTheme() {
    //ogni volta che cambia il colore primario o secondario viene aggiornato

    dataTheme.palette.primary.main = primaryColor;
    dataTheme.palette.secondary.main = secondaryColor;
    dataTheme.palette.background.default = backgroundColor;
    dataTheme.palette.background.contrastDefault =
      outerTheme.palette.getContrastText(dataTheme.palette.background.default);
    dataTheme.palette.background.contrastPaper =
      outerTheme.palette.getContrastText(dataTheme.palette.background.paper);

    dataTheme.palette.text.primary = getContrastColors(
      primaryColor,
      "#ffffff",
      0.85
    );
    dataTheme.palette.text.secondary = getContrastColors(
      secondaryColor,
      "#ffffff",
      0.85
    );

    dataTheme.palette.text.paper = getContrastColors(
      primaryColor,
      dataTheme.palette.background.paper,
      0.55
    );

    dataTheme.palette.text.background = getContrastColors(
      "#ffffff",
      dataTheme.palette.background.default,
      0.55
    );
    dataTheme.palette.text.backgroundSecondary = getContrastColors(
      secondaryColor,
      backgroundColor,
      0.55
    );
    dataTheme.palette.text.contrastPrimary = getContrastColors(
      "#ffffff",
      primaryColor,
      0.55
    );
    dataTheme.palette.text.contrastSecondary = getContrastColors(
      "#ffffff",
      secondaryColor,
      0.55
    );

    dataTheme.typography = {
      h1: {
        fontFamily: headerFontFamily,
        //fontSize: parseInt(fontSize) + 20 + 'px',
      },
      h2: {
        fontFamily: headerFontFamily,
        //fontSize: parseInt(fontSize) + 15 + 'px',
      },
      h3: {
        fontFamily: headerFontFamily,
        //fontSize: parseInt(fontSize) + 10 + 'px',
      },
      h4: {
        fontFamily: headerFontFamily,
        //fontSize: parseInt(fontSize) + 5 + 'px',
      },
      h5: {
        fontFamily: headerFontFamily,
        //fontSize: parseInt(fontSize) + 3 + 'px',
      },
      h6: {
        fontFamily: headerFontFamily,
        //fontSize: parseInt(fontSize) + 2 + 'px',
      },
      h7: {
        fontFamily: headerFontFamily,
        //fontSize: parseInt(fontSize) + 1 + 'px',
      },
      subtitle1: {
        fontFamily: bodyFontFamily,
        //fontSize: parseInt(fontSize) + 0 + 'px',
      },
      subtitle2: {
        fontFamily: bodyFontFamily,
        //fontSize: parseInt(fontSize) - 1 + 'px',
      },
      body: {
        fontFamily: bodyFontFamily,
        //fontSize: parseInt(fontSize) + 0 + 'px',
      },
      body1: {
        fontFamily: bodyFontFamily,
        //fontSize: parseInt(fontSize) - 1 + 'px',
      },
      body2: {
        fontFamily: bodyFontFamily,
        //fontSize: parseInt(fontSize) - 2 + 'px',
      },
      button: {
        fontFamily: buttonFontFamily,
        //fontSize: parseInt(fontSize) - 2 + 'px',
      },
      fontFamily: bodyFontFamily,
    };

    dataTheme.components = {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Dancing Script';
            src: url('./ttf/DancingScript-Regular.ttf') format('truetype');
            font-weight: normal;
            font-style: normal;
            transform: scale(0.1);
          } `,
      },
    };

    // Textfield
    dataTheme.components.MuiTextField = {
      styleOverrides: {
        root: {
          // Permette la selezione del contenuto interno dei TextField
          "& .MuiInputBase-root": {
            userSelect: "text !important", // Assicura che il testo sia copiabile
          },
          "& .MuiInputBase-input": {
            userSelect: "text !important", // Include l'input interno
          },
          "& label.Mui-focused": {
            color: dataTheme.palette.text.paper,
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: dataTheme.palette.text.paper,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: dataTheme.palette.text.paper,
            },
            "&:hover fieldset": {
              borderColor: dataTheme.palette.text.paper,
            },
            "&.Mui-focused fieldset": {
              borderColor: dataTheme.palette.text.paper,
            },
          },
        },
      },
    };

    //style outlined input
    dataTheme.components.MuiOutlinedInput = {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiInputLabel-root": {
            color: dataTheme.palette.text.paper,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: dataTheme.palette.text.paper,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: dataTheme.palette.text.paper,
          },
        },
      },
    };

    //style select input
    dataTheme.components.MuiSelect = {
      styleOverrides: {
        select: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: dataTheme.palette.text.paper,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: dataTheme.palette.text.paper,
          },
        },
      },
    };

    dataTheme.components.MuiCheckbox = {
      styleOverrides: {
        root: {
          color: "black", // Colore di default
          "&.Mui-checked": {
            color: "black", // Colore quando è selezionato
          },
        },
      },
    };
    dataTheme.components.MuiSwitch = {
      styleOverrides: {
        root: {
          "& .MuiSwitch-switchBase.Mui-checked": {
            color: "#000",
          },
          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#000000d1",
          },
        },
        track: {
          backgroundColor: "#00000085",
        },
      },
    };

    dataTheme.components.Switch = {
      styleOverrides: {
        root: {
          color: "black", // Colore di default
          "&.Mui-checked": {
            color: "black", // Colore quando è selezionato
          },
        },
      },
    };

    dataTheme.components.MuiInputLabel = {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: dataTheme.palette.text.paper,
          },
        },
      },
    };

    // Accordion
    dataTheme.components.MuiAccordion = {
      styleOverrides: {
        root: {
          backgroundColor: dataTheme.palette.background.accordion, // Imposta il colore di sfondo per l'Accordion
        },
      },
    };

    dataTheme.components.MuiAccordionSummary = {
      styleOverrides: {
        root: {
          backgroundColor: dataTheme.palette.background.accordion, // Imposta il colore di sfondo per l'AccordionSummary
        },
      },
    };

    dataTheme.components.MuiAccordionDetails = {
      styleOverrides: {
        root: {
          backgroundColor: dataTheme.palette.background.accordion, // Imposta il colore di sfondo per l'AccordionDetails
        },
      },
    };

    dataTheme.components.MuiButton = {
      defaultProps: {
        variant: "contained",
      },

      styleOverrides: {
        root: {
          variant: "contained", // Imposta il colore di sfondo per l'AccordionDetails
        },
      },
    };

    dataTheme.components.MuiDialogActions = {
      styleOverrides: {
        root: {},
      },
    };

    setOuterTheme(createTheme(dataTheme));
  }

  useEffect(() => {
    fetchOutherTheme();
  }, [
    primaryColor,
    secondaryColor,
    headerFontFamily,
    buttonFontFamily,
    bodyFontFamily,
    fontSize,
    backgroundColor,
    localData,
  ]);

  const providerValue = useMemo(
    () => ({
      setDefaultTheme,
      getContrastColors,
      isAdmin,
      userObject,
      setUserObject,
      token,
      setToken,
      logged,
      setLogged,
      user,
      setUser,
      loadingUser,
      setLoadingUser,
      isLoggedIn,
      setIsLoggedIn,
      user_licenses,
      setUserLicenses,
      outerTheme,
      primaryColor,
      secondaryColor,
      setPrimaryColor,
      setSecondaryColor,
      openBackDrop,
      setOpenBackDrop,
      IsAllowed,
      customerView,
      setCustomerView,
      headerFontFamily,
      buttonFontFamily,
      bodyFontFamily,
      setHeaderFontFamily,
      setButtonFontFamily,
      setBodyFontFamily,
      setFontSize,
      fontSize,
      backgroundColor,
      setBackgroundColor,
      managerViewState,
      setManagerViewState,
      userNotifications,
      setUserNotifications,
      setUserValues,
      GetLocalIdParams,
      clearLocalParamsCache,
      localData,
      setReferrerLocalId,
      referrerLocalId,
      localSubscriptions,
      openLoginModal,
      setOpenLoginModal,
      userPromotions,
      userConsents,
      tokenExpired,
    }),
    [
      setDefaultTheme,
      getContrastColors,
      isAdmin,
      userObject,
      setUserObject,
      token,
      setToken,
      logged,
      setLogged,
      user,
      setUser,
      token,
      loadingUser,
      setLoadingUser,
      isLoggedIn,
      setIsLoggedIn,
      user_licenses,
      setUserLicenses,
      outerTheme,
      primaryColor,
      secondaryColor,
      setPrimaryColor,
      setSecondaryColor,
      openBackDrop,
      setOpenBackDrop,
      IsAllowed,
      customerView,
      setCustomerView,
      headerFontFamily,
      buttonFontFamily,
      bodyFontFamily,
      setHeaderFontFamily,
      setButtonFontFamily,
      setBodyFontFamily,
      setFontFamily,
      setFontSize,
      fontSize,
      managerViewState,
      setManagerViewState,
      setUserNotifications,
      GetLocalIdParams,
      clearLocalParamsCache,
      localData,
      setReferrerLocalId,
      referrerLocalId,
      localSubscriptions,
      openLoginModal,
      setOpenLoginModal,
      userPromotions,
      userConsents,
      tokenExpired,
    ]
  );

  function setUserValues(json) {
    setUserLicenses(json.user_licenses);
    setUserNotifications(json.notification);
    setUserConsents(json.newsletter_consent);
    setLocalSubscriptions(json.local_subscriptions ?? []);
    setUserPromotions(json.user_promotions);
    setUser(json.user);
  }

  function tokenExpired() {
    sessionStorage.clear();
    setUserObject({});
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("userObject");
    //setLogged(false);
    //setOpenBackDrop(false)
  }




  async function fetchUser() {
    localStorage.getItem("token");
    const requestOptionsGet = {
      method: "GET",
      headers: { Authentication: token },
    };
    if (token != undefined && token != null) {
      const response = await fetch(
        "/api/users/current_user",
        requestOptionsGet
      ).then((res) => res.json());
      if (response.status === "success") {
        setUserValues(response.data);
        setUserId(response.data.user_id);
      } else {
        if (
          response.error_code === "gtoken expired" ||
          response.error_code === "gtoken expired no reload"
        ) {
          tokenExpired();
        }
      }
    }
    setLoadingUser(false);
  }

  pubSubService(`current_user_${userId}`, fetchUser);



  useEffect(() => {
    setIsAdmin(IsAllowed(undefined, ["ADMIN"]));
  }, [user_licenses]);

  useEffect(() => {
    fetchUser();
    if (logged) {
      setOpenLogin(false);
    }
    try {
      var userObjectTemp = jwt_decode(token);
      setUserObject(userObjectTemp);
      setLogged(true);
    } catch {
      setUserObject({});
    }

    //GetCurrentUser();
  }, [token, logged]);

  //** settaggi colori local_id **/
  const [localIdCache, setLocalIdCache] = useState({});

  function clearLocalParamsCache(local_id) {
    try {
      delete localIdCache[local_id];
    } catch {}
  }

  useEffect(() => {
    // Aggiorna il titolo della pagina
    document.title = localData.local_name
      ? localData.local_name
      : "Overline Menù";

    // Aggiorna dinamicamente la descrizione
    const description = localData.local_description?.it
      ? localData.local_description.it
      : "Descrizione non disponibile";
    let metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", description);
    } else {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      metaDescription.content = description;
      document.head.appendChild(metaDescription);
    }

    // Aggiorna dinamicamente il telefono
    const telephone = localData.local_phone
      ? localData.local_phone
      : "Telefono non disponibile";
    let metaTelephone = document.querySelector('meta[name="telephone"]');
    if (metaTelephone) {
      metaTelephone.setAttribute("content", telephone);
    } else {
      metaTelephone = document.createElement("meta");
      metaTelephone.name = "telephone";
      metaTelephone.content = telephone;
      document.head.appendChild(metaTelephone);
    }

    // Aggiorna dinamicamente l'indirizzo
    const address = localData.local_address
      ? localData.local_address
      : "Indirizzo non disponibile";
    let metaAddress = document.querySelector('meta[name="address"]');
    if (metaAddress) {
      metaAddress.setAttribute("content", address);
    } else {
      metaAddress = document.createElement("meta");
      metaAddress.name = "address";
      metaAddress.content = address;
      document.head.appendChild(metaAddress);
    }

    // Aggiorna dinamicamente l'immagine Open Graph
    const imageUrl = localData.local_logo
      ? `${window.location.origin}/local_logo/${localData.local_id}`
      : "/Logo.png";
    let metaOgImage = document.querySelector('meta[property="og:image"]');
    if (metaOgImage) {
      metaOgImage.setAttribute("content", imageUrl);
    } else {
      metaOgImage = document.createElement("meta");
      metaOgImage.setAttribute("property", "og:image");
      metaOgImage.setAttribute("content", imageUrl);
      document.head.appendChild(metaOgImage);
    }
  }, [localData]);

  function generateUniqueId() {
    return "_" + Math.random().toString(36).substr(2, 9);
  }

  async function GetLocalIdParams(local_id) {
    // Verifica se i dati per questo local_id sono già in cache

    if (localIdCache[local_id]) {
      const cachedData = localIdCache[local_id];
      // Utilizza i dati in cache invece di effettuare una nuova richiesta
      setValuesFromData(cachedData);
      setLocalData({ ...cachedData });
      FaviconUpdater(local_id);
      return cachedData;
    }

    const queryString = new URLSearchParams({ local_id }).toString();
    const url = "/api/locals/local_info_params";
    let uniqueId = localStorage.getItem("uniqueId");

    if (!uniqueId) {
      uniqueId = generateUniqueId();
      localStorage.setItem("uniqueId", uniqueId);
    }
    // Aggiunge la stringa dei parametri query all'URL se presenti
    const fullUrl = queryString ? `${url}?${queryString}` : url;

    const requestOptions = {
      method: "GET",
      headers: {
        "Unique-Id": uniqueId, // Aggiunge l'header
        "Content-Type": "application/json", // Opzionale, se necessario
      },
    };

    let localDataTemp = null;
    let success = false;
    let retries = 0;
    const maxRetries = 5; // ad esempio, puoi provare fino a 5 volte
    const delay = 5000; // aspetta 5 secondi tra i tentativi

    while (!success && retries < maxRetries) {
      try {
        const response = await fetch(fullUrl, requestOptions);

        // Se ricevi una risposta ma non è OK (es. 404, 500), lancia un errore
        if (!response.ok) {
          throw new Error("Server returned an error status");
        }

        const data = await response.json();
        if (!data.data) {
          throw new Error("Server returned an error in local info params");
        }
        localDataTemp = data.data;
        success = true;
      } catch (error) {
        console.error(error);
        retries++;

        // Aspetta per un intervallo di tempo prima di riprovare
        if (retries < maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
      }
    }

    if (!success) {
      console.error("Failed to fetch data after multiple retries");
    }
    if (localDataTemp) {
      // Memorizza i dati nella cache
      localIdCache[local_id] = localDataTemp;

      // Utilizza i dati appena caricati
      setValuesFromData(localDataTemp);
      setLocalData(localDataTemp);
      FaviconUpdater(local_id);
    }

    return localDataTemp;
  }

  const settingsMap = {
    local_primary_color: [setPrimaryColor, "primaryColor"],
    local_secondary_color: [setSecondaryColor, "secondaryColor"],
    local_background_color: [setBackgroundColor, "backgroundColor"],
    local_header_font_family: [setHeaderFontFamily, "headerFontFamily"],
    local_body_font_family: [setBodyFontFamily, "fontBodyFamily"],
  };

  function setValuesFromData(localData) {
    for (const [key, [setter, sessionKey]] of Object.entries(settingsMap)) {
      if (localData[key]) {
        setter(localData[key]);
        localStorage.setItem(sessionKey, localData[key]);
      } else {
        console.error(key + " color localData not found");
      }
    }
  }

  return (
    <UserContext.Provider value={providerValue}>
      <RenderModal
        open={openLogin}
        setOpen={setOpenLogin}
        header={"La tua sessione è scaduta"}
        body={
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="body">
              {" "}
              Effettua il login per continuare
            </Typography>
            <LoginButton />
          </Box>
        }
      />
      {children}
    </UserContext.Provider>
  );
};
