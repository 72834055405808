import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Typography,
  Accordion,
  Badge,
  AccordionSummary,
  AccordionDetails,
  Box,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import ProductsMenuSection from "./ProductsMenuSection";
import { getContrastColors } from "../../Functions/Colors";
import { calculateVisibilityCounts } from "./MenuFunctions";

export default function MenuStandard({
  basket,
  setBasket,
  getSectionCover,
  getSubSectionCover = () => {console.log('sub section fetch')},
  getProductCover,
  menuData = {},
  section_names = {},
  sectionsOrders,
  productsIdHidden = [],
  isStaff = false,
  card_variant_selected = "default",
  card_animation_variant_selected,
  menuCardShadow,
  menuAddProductDialogFullscreen,
  menuAddProductDialogAnimation,
}) {
  const theme = useTheme();

  const [responsiveStyle, setResponsiveStyle] = useState({
    xs: 12,
    sm: 12,
    md: 6,
    lg: 4,
    xl: 3,
  });

  useEffect(() => {
    if (
      (card_variant_selected === "image") |
      (card_variant_selected === "glass-effect")
    ) {
      setResponsiveStyle({ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 });
    } else {
      setResponsiveStyle({ xs: 12, sm: 12, md: 6, lg: 4, xl: 3 });
    }
  }, [card_variant_selected]);

  return (
    <Box style={{ padding: "0px" }}>
      {sectionsOrders.map((section_id) => {
        const section_values = section_names[section_id];
        const section_name = section_values.section_name;
        const section_cover = section_values.section_cover;
        const section_color = section_values.section_color;
        const section_card_color = section_values.section_card_color;
        const section_color_contrast = section_color
          ? getContrastColors("#ffffff", section_color)
          : undefined;

        const { elementsShowed, elementsAvaiable } = calculateVisibilityCounts(
          menuData,
          section_id,
          productsIdHidden
        );

        if (elementsAvaiable > 0) {
          return (
            <Accordion
              key={"section_id-" + section_id}
              id={"section-name-" + section_name}
              slotProps={{
                transition: { unmountOnExit: true },
              }}
              onChange={(event, expanded) => {
                if (expanded) {
                  setTimeout(() => {
                    const scrollToElement = () => {
                      const summaryElement = document.getElementById(
                        "accordion-summary-" + section_id
                      );
                      if (summaryElement) {
                        const yOffset = +20; // Scendi di 20px in più
                        const y =
                          summaryElement.getBoundingClientRect().top +
                          window.pageYOffset +
                          yOffset;
                        window.scrollTo({ top: y, behavior: "smooth" });
                      }
                    };

                    scrollToElement();

                    // Secondo tentativo di scroll dopo un breve ritardo
                    setTimeout(() => {
                      scrollToElement();
                    }, 500); // Ritardo di 500 ms per il secondo tentativo
                  }, 0); // Attendi 50 ms prima di eseguire il primo scroll
                }
              }}
            >
              <AccordionSummary
                id={"accordion-summary-" + section_id}
                sx={{
                  position: "sticky",
                  top: "0px",
                  zIndex: 10,
                  boxShadow: 2,
                  background: section_cover && "transparent",
                  backgroundColor: section_color
                    ? section_color
                    : "rgb(255 255 255 / 95%)",
                  color: section_color && section_color_contrast,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Stack
                  style={{ width: "100%" }}
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {productsIdHidden.length > 0 && (
                    <Badge
                      badgeContent={`${elementsShowed}`}
                      sx={{
                        "& .MuiBadge-badge": {
                          backgroundColor: theme.palette.secondary.dark,
                          color: "#fff", // Colore del testo
                        },
                      }}
                    >
                      <FilterListIcon />
                    </Badge>
                  )}
                  <Typography align="center" variant="h5">
                    {section_name}
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                style={{ padding: "0px", minHeight: "75vh" }}
                key={"tab-panel-" + section_id}
                index={section_id}
                dir={theme.direction}
              >
                <ProductsMenuSection
                  sectionMenuData={menuData[section_id]}
                  section_description={section_values.section_description}
                  getSubSectionCover={getSubSectionCover}
                  section_id={section_id}
                  productsIdHidden={productsIdHidden}
                  isStaff={isStaff}
                  basket={basket}
                  setBasket={setBasket}
                  section_color={"#ffffff"}
                  section_color_contrast={section_color_contrast}
                  section_card_color={section_card_color}
                  section_name={section_name}
                  section_cover={section_values.section_cover}
                  card_variant_selected={card_variant_selected}
                  card_animation_variant_selected={
                    card_animation_variant_selected
                  }
                  getProductCover={getProductCover}
                  getSectionCover={getSectionCover}
                  responsiveStyle={responsiveStyle}
                  menuCardShadow={menuCardShadow}
                  menuAddProductDialogFullscreen={
                    menuAddProductDialogFullscreen
                  }
                  menuAddProductDialogAnimation={menuAddProductDialogAnimation}
                />
              </AccordionDetails>
            </Accordion>
          );
        }
      })}
    </Box>
  );
}

MenuStandard.propTypes = {
  section_names: PropTypes.object,
  productsIdHidden: PropTypes.array,
  menuData: PropTypes.object,
  isStaff: PropTypes.bool,
  menuDataIngredients: PropTypes.object,
  card_variant_selected: PropTypes.string,
  getProductCover: PropTypes.func,
};
