import React from "react";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import ProductMenuCard from "../../Cards/ProductMenuCard";
import { useTranslation } from "react-i18next";
const ProductMenuCardMemo = React.memo(ProductMenuCard);

const ProductsMenuList = ({
  sectionMenuData,
  productsIdHidden,
  isStaff,
  basket,
  setBasket,
  section_card_color,
  card_variant_selected,
  card_animation_variant_selected,
  getProductCover,
  responsiveStyle,
  menuCardShadow,
  menuAddProductDialogFullscreen,
  menuAddProductDialogAnimation,
}) => {
  const visibleProducts = sectionMenuData.filter(
    (product) => !productsIdHidden.includes(product.product_id)
  );
  const hiddenProducts = sectionMenuData.filter((product) =>
    productsIdHidden.includes(product.product_id)
  );
  const { i18n } = useTranslation();
  const language = i18n.language.toLowerCase();
  return (
    <Grid
      container
      spacing={card_variant_selected === "no-background" ? 0 : 2}
      justifyContent="center"
      alignItems="center"
    >
      {visibleProducts.map((product) => {
        return (
          <Grid
            key={"product-" + product.product_id}
            item
            {...responsiveStyle}
            style={{
              display: product.product_hidden == false ? "flex" : "none",
            }}
          >
            <ProductMenuCardMemo
              index={product.product_id}
              basket={basket}
              setBasket={setBasket}
              product_id={product.product_id}
              product_name={product.product_name}
              product_description={product.product_description}
              product_storytelling={product.product_storytelling}
              product_price={product.product_price}
              product_perfect_service={product.product_perfect_service}
              product_cover={product.product_cover}
              product_currency={product.product_currency}
              product_formats={product.product_formats}
              product_custom_text={
                product?.product_custom_text?.[language]
                  ? product?.product_custom_text?.[language]
                  : product?.product_custom_text?.it
              }
              productIngredients={product.product_ingredients}
              productAllergens={product.allergens_name}
              productCategoriesFood={product.categories_food_name}
              productTag={product.productTag}
              card_variant_selected={card_variant_selected}
              card_animation_variant_selected={card_animation_variant_selected}
              getProductCover={getProductCover}
              menuCardShadow={menuCardShadow}
              section_card_color={section_card_color}
              menuAddProductDialogFullscreen={menuAddProductDialogFullscreen}
              menuAddProductDialogAnimation={menuAddProductDialogAnimation}
              productAlcoholicGrades={product.productAlcoholicGrades}
            />
          </Grid>
        );
      })}

      {/* Seconda parte: Prodotti nascosti */}
      {hiddenProducts.length > 0 && (
        <>
          <Grid item xs={12}>
            <Alert
              sx={{ margin: "30px" }}
              severity="info"
              icon={<KeyboardDoubleArrowDownIcon />}
            >
              <Typography variant="body" component="span">
                Prodotti non inclusi nei filtri
              </Typography>
            </Alert>
          </Grid>
          {hiddenProducts.map((product) => {
            return (
              <Grid
                item
                {...responsiveStyle}
                key={"product-" + product.product_id}
                style={{
                  display: product.product_hidden == false ? "flex" : "none",
                  filter: "grayscale(1) brightness(0.5)",
                }}
              >
                <ProductMenuCardMemo
                  index={product.product_id}
                  setBasket={setBasket}
                  product_id={product.product_id}
                  product_name={product.product_name}
                  product_description={product.product_description}
                  product_storytelling={product.product_storytelling}
                  product_custom_text={
                    product?.product_custom_text?.[language]
                      ? product?.product_custom_text?.[language]
                      : product?.product_custom_text?.it
                  }
                  product_price={product.product_price}
                  product_perfect_service={product.product_perfect_service}
                  product_cover={product.product_cover}
                  product_currency={product.product_currency}
                  product_formats={product.product_formats}
                  productIngredients={product.product_ingredients}
                  productAllergens={product.allergens_name}
                  productCategoriesFood={product.categories_food_name}
                  productTag={product.productTag}
                  card_variant_selected={card_variant_selected}
                  card_animation_variant_selected={
                    card_animation_variant_selected
                  }
                  getProductCover={getProductCover}
                  menuCardShadow={menuCardShadow}
                  section_card_color={section_card_color}
                  menuAddProductDialogFullscreen={
                    menuAddProductDialogFullscreen
                  }
                  menuAddProductDialogAnimation={menuAddProductDialogAnimation}
                  productAlcoholicGrades={product.productAlcoholicGrades}
                />
              </Grid>
            );
          })}
        </>
      )}
    </Grid>
  );
};

export default ProductsMenuList;
