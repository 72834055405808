import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useTheme } from "@mui/material/styles";

/*import {handleResponse} from '../../api/api'*/
import {
  Button,
  Typography,
  ButtonBase,
  Grid,
  Zoom,
  Box,
  IconButton,
  Stack,
  Card,
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from "@mui/icons-material/Block";
import { RenderIcon } from "../../../../story-components/components";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterAltOffRoundedIcon from "@mui/icons-material/FilterAltOffRounded";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";

export default function ModalFilterMenu({
  open,
  setOpen,
  menuData,
  appliedFilters,
  setAppliedFilters,
  setProductsIdHidden,
}) {
  const theme = useTheme();
  const { i18n } = useTranslation();
  const language = i18n.language ? i18n.language.toLowerCase() : null;
  function getFilterApplied(filterValue, dataType) {
    if (filterValue !== undefined) {
      return filterValue;
    } else {
      return dataType;
    }
  }

  var [searchIngredient, setSearchIngredient] = useState(
    getFilterApplied(appliedFilters.searchIngredient, [])
  );
  var [unionId, setUnionId] = useState([]);
  const [productIdIngredientSelected, setProductIdIngredientSelected] =
    useState([]);
  let [nProducts, setNProducts] = useState(0);

  function applyFilters() {
    setProductsIdHidden([...unionId]);
  }

  const getIngredientNamesAndProductIds = (menuData, language) => {
    let ingredientNames = new Set();
    let ingredientToProductIds = {};

    for (let key in menuData) {
      menuData[key].products.forEach((product) => {
        for (let category in product.product_ingredients) {
          product.product_ingredients[category].forEach((ingredient) => {
            let ingredientName = ingredient.ingredientName[language];
            ingredientNames.add(ingredientName);

            if (!ingredientToProductIds[ingredientName]) {
              ingredientToProductIds[ingredientName] = [];
            }

            ingredientToProductIds[ingredientName].push(product.product_id);
          });
        }
      });
    }

    return [Array.from(ingredientNames), ingredientToProductIds];
  };

  // Uso
  const [ingredientNames, setIngredientNames] = useState([]);
  const [ingredientToProductIds, setIngredientToProductIds] = useState([]);

  useEffect(() => {
    if (open) {
      window.history.pushState("fake-route", document.title, "");
      window.addEventListener("popstate", () => setOpen(false));
    }
  }, [open]);

  useEffect(() => {
    let [ingredientNamesTemp, ingredientToProductIdsTemp] =
      getIngredientNamesAndProductIds(menuData, "it");
    setIngredientNames(ingredientNamesTemp);
    setIngredientToProductIds(ingredientToProductIdsTemp);
  }, [menuData]);

  useEffect(() => {
    function checkValues(value, valueInclude) {
      if (value != undefined && value != null) {
        return value.includes(valueInclude);
      } else {
        return false;
      }
    }

    if (menuData !== undefined && Object.keys(menuData).length !== 0) {
      //hidden names
      var hiddenAllergens = [];
      var hiddenCategories = [];

      var allProductIds = new Set(); // nuovo set per contenere tutti i product id

      let nProducts = 0;
      for (let section_id in menuData) {
        nProducts += menuData[section_id].products.length;

        // Ciclo sui prodotti principali
        menuData[section_id].products.forEach((product) => {
          allProductIds.add(product.product_id); // Aggiungi ogni product_id al set
        });

        // Ciclo sulle sotto-sezioni
        Object.values(menuData[section_id].subSections).forEach(
          (subSection) => {
            nProducts += subSection.subSectionData.length; // Aggiungi la lunghezza di subSectionData a nProducts

            subSection.subSectionData.forEach((product) => {
              allProductIds.add(product.product_id); // Aggiungi ogni product_id al set da subSectionData
            });
          }
        );

        // Filtraggio per categorie
        for (let categoryName in appliedFilters["categories"]) {
          if (appliedFilters.categories[categoryName].checked) {
            let menuDataCategoriesTemp = menuData[section_id].products.filter(
              (product) =>
                checkValues(product.categories_food_name, categoryName) ===
                false
            );
            menuDataCategoriesTemp = menuDataCategoriesTemp.concat(
              Object.values(menuData[section_id].subSections).flatMap(
                (subSection) =>
                  subSection.subSectionData.filter(
                    (product) =>
                      checkValues(
                        product.categories_food_name,
                        categoryName
                      ) === false
                  )
              )
            );
            let hiddenCategoriesTemp = [
              ...new Set(menuDataCategoriesTemp.map((item) => item.product_id)),
            ];
            hiddenCategories = [
              ...new Set([...hiddenCategories, ...hiddenCategoriesTemp]),
            ]; // Union
          }
        }

        // Filtraggio per allergeni
        for (const allergen_name in appliedFilters["allergens"]) {
          if (appliedFilters.allergens[allergen_name].checked) {
            let menuDataAllergensTemp = menuData[section_id].products.filter(
              (ingredientTable) =>
                checkValues(ingredientTable.allergens_name, allergen_name)
            );
            menuDataAllergensTemp = menuDataAllergensTemp.concat(
              Object.values(menuData[section_id].subSections).flatMap(
                (subSection) =>
                  subSection.subSectionData.filter((ingredientTable) =>
                    checkValues(ingredientTable.allergens_name, allergen_name)
                  )
              )
            );
            let hiddenAllergensTemp = [
              ...new Set(menuDataAllergensTemp.map((item) => item.product_id)),
            ];
            hiddenAllergens = [
              ...new Set([...hiddenAllergens, ...hiddenAllergensTemp]),
            ]; // Union
          }
        }
      }

      const hiddenIngredientsName =
        productIdIngredientSelected.length > 0
          ? [...allProductIds].filter(
              (productId) => !productIdIngredientSelected.includes(productId)
            )
          : [];

      const hiddenTextIds = performTextSearch(appliedFilters.searchText);

      setNProducts(nProducts);
      setUnionId([
        ...new Set([
          ...hiddenCategories,
          ...hiddenAllergens,
          ...hiddenIngredientsName,
          ...hiddenTextIds,
        ]),
      ]);
    }
  }, [appliedFilters]);

  function updateAllergensCategorie() {
    let categories = {};
    let allergens = {};
    console.log("menuData:", menuData);
    if (Object.keys(menuData).length > 0) {
      for (let section_id in menuData) {
        // Processa i prodotti della sezione
        menuData[section_id].products.forEach((product) => {
          if (product.categories_food_name) {
            product.categories_food_name.forEach((cat) => {
              categories[cat] = { checked: false };
            });
          }
          if (product.allergens_name) {
            product.allergens_name.forEach((allergen) => {
              allergens[allergen] = { checked: false };
            });
          }
        });

        // Processa i prodotti delle sotto-sezioni
        if (menuData[section_id].subSections) {
          for (let subSectionId in menuData[section_id].subSections) {
            if (menuData[section_id].subSections[subSectionId].subSectionData) {
              menuData[section_id].subSections[subSectionId].subSectionData.forEach(
                (product) => {
                  if (product.categories_food_name) {
                    product.categories_food_name.forEach((cat) => {
                      categories[cat] = { checked: false };
                    });
                  }
                  if (product.allergens_name) {
                    product.allergens_name.forEach((allergen) => {
                      allergens[allergen] = { checked: false };
                    });
                  }
                }
              );
            }
          }
        }
      }
    }
    appliedFilters["categories"] = categories;
    appliedFilters["allergens"] = allergens;
    setAppliedFilters({ ...appliedFilters });
  }

  useEffect(() => {
    if (menuData !== undefined) {
      updateAllergensCategorie();
      let productNames = {};
      let productsId = {};
      for (let section_id in menuData) {
        menuData[section_id].products.forEach((product) => {
          productNames[product.product_name] = true;
          productsId[product.product_id] = true;
        });
      }
    }
  }, [menuData]);

  function clearFilters() {
    setSearchIngredient([]);
    // Ottenere le chiavi dell'oggetto 'allergens' all'interno di 'appliedFilters'
    const allergenKeys = Object.keys(appliedFilters["allergens"]);

    // Iterare attraverso le chiavi usando un ciclo for
    for (let i = 0; i < allergenKeys.length; i++) {
      // Ottenere il nome dell'allergene dalla chiave corrente
      const allergen_name = allergenKeys[i];

      // Impostare la proprietà 'checked' su false
      appliedFilters["allergens"][allergen_name].checked = false;
    }

    // Ottieni le chiavi dell'oggetto 'categories' in 'appliedFilters'
    const categoryKeys = Object.keys(appliedFilters["categories"]);

    // Utilizza un ciclo 'for' per iterare su ogni chiave
    for (let i = 0; i < categoryKeys.length; i++) {
      // Ottieni il nome della categoria corrente
      const categoryName = categoryKeys[i];

      // Imposta la proprietà 'checked' su false per la categoria corrente
      appliedFilters["categories"][categoryName].checked = false;
    }

    appliedFilters["productsIdHidden"] = [];

    let shallow = Object.assign({}, appliedFilters);
    setAppliedFilters(shallow);
    setProductsIdHidden([]);
    setOpen(false);
  }

  function RenderModalHeader() {
    return (
      <Stack
        sx={{ boxShadow: 2 }}
        style={{
          position: "sticky",
          zIndex: 999,
          top: "0px",
          color: "white",
        }}
        direction={"row"}
        spacing={2}
      >
        <IconButton
          onClick={() => setOpen(false)}
          style={{
            zIndex: "100",
            display: "inline-block",
            position: "fixed",
            top: 5,
            right: 5,
            width: "35px",
            height: "35px",
            padding: "5px",
            background: "rgb(0 0 0 / 15%)",
          }}
        >
          <CloseRoundedIcon style={{ color: "white" }} />
        </IconButton>
        <Box>
          <Typography
            style={{
              padding: "5px",
              color: "white",
            }}
            color="primary"
            id="modal-modal-title"
            variant="h6"
            component="h6"
          >
            <Trans>Filtri</Trans>
          </Typography>

          <Typography
            style={{
              padding: "5px",
              color: "white",
              alignSelf: "center",
            }}
            color="primary"
            id="modal-modal-title"
            variant="body2"
            component="body"
          >
            {nProducts - unionId.length} su {nProducts} prodotti selezionati
          </Typography>
        </Box>
      </Stack>
    );
  }

  useEffect(() => {
    appliedFilters.searchIngredient = searchIngredient;
    setAppliedFilters({ ...appliedFilters });
  }, [searchIngredient]);

  function AddCheckBox(typeName, title, include) {
    function handleSelection(itemName) {
      appliedFilters[typeName][itemName].checked =
        !appliedFilters[typeName][itemName].checked;
      setAppliedFilters({ ...appliedFilters });
    }

    if (Object.keys(appliedFilters[typeName]).length > 0) {
      return (
        <Card style={{ margin: "0px", padding: "5px" }}>
          <Stack>
            <Typography
              style={{
                display: "block",
                textAlignLast: "center",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h6"
            >
              <Trans>{title}</Trans>
            </Typography>
            <Grid style={{}} container spacing={2}>
              {Object.keys(appliedFilters[typeName]).map((itemName, index) => {
                const checked = appliedFilters[typeName][itemName].checked;
                return (
                  <Grid
                    key={"filter-check-box-" + itemName}
                    item
                    xs={6}
                    md={3}
                    lg={2}
                    style={{}}
                  >
                    {include ? (
                      <ButtonBase
                        sx={{ boxShadow: checked ? 3 : 1 }}
                        style={{
                          borderRadius: "5px",
                          padding: "5px",
                          height: "100%",
                          width: "100%",
                          backgroundColor: checked
                            ? theme.palette.secondary.dark
                            : theme.palette.background.paper,
                        }}
                        onClick={() => handleSelection(itemName)}
                      >
                        <Stack
                          direction={"row"}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Zoom timeout={500} in={checked} unmountOnExit>
                            <CheckCircleIcon
                              style={{
                                color: "white",
                                position: "inherit",
                                float: "left",
                              }}
                            />
                          </Zoom>

                          <RenderIcon
                            iconName={itemName}
                            backgroundColor={theme.palette.secondary.dark}
                            color={"white"}
                            showText={false}
                          />

                          <Typography
                            style={{
                              display: "block",
                              placeSelf: "center",
                              color: checked ? "white" : "grey",
                              width: "inherit",
                            }}
                            id="modal-modal-title"
                            variant="button"
                            component="h6"
                          >
                            <Trans>{itemName}</Trans>
                          </Typography>
                        </Stack>
                      </ButtonBase>
                    ) : (
                      <ButtonBase
                        sx={{ boxShadow: 3 }}
                        style={{
                          borderRadius: "5px",
                          padding: "5px",
                          height: "100%",
                          width: "100%",
                          backgroundColor: checked
                            ? theme.palette.background.paper
                            : theme.palette.secondary.dark,
                        }}
                        onClick={() => handleSelection(itemName)}
                      >
                        <Stack
                          direction={"row"}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Zoom timeout={500} in={checked} unmountOnExit>
                            <BlockIcon
                              style={{
                                color: "red",
                                position: "inherit",
                                float: "left",
                              }}
                            />
                          </Zoom>
                          <RenderIcon
                            iconName={itemName}
                            backgroundColor={theme.palette.primary.dark}
                            color={"white"}
                            showText={false}
                          />

                          <Typography
                            style={{
                              display: "block",
                              textDecorationLine: checked
                                ? "line-through"
                                : undefined,
                              placeSelf: "center",
                              color: checked ? "grey" : "white",
                              width: "inherit",
                            }}
                            id="modal-modal-title"
                            variant="button"
                            component="h6"
                          >
                            <Trans>{itemName}</Trans>
                          </Typography>
                        </Stack>
                      </ButtonBase>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </Card>
      );
    }
  }

  // ricarca del testo globale //
  // Stati per la gestione dell'input e del ritardo
  const [searchText, setSearchText] = useState("");
  const [timer, setTimer] = useState(null);

  // Gestisce l'aggiornamento dell'input e imposta il ritardo
  const handleSearchChange = (event) => {
    clearTimeout(timer); // Annulla il timeout precedente
    const newValue = event.target.value;
    setSearchText(newValue); // Aggiorna il valore di ricerca
    setTimer(
      setTimeout(() => {
        appliedFilters.searchText = newValue;
        setAppliedFilters({ ...appliedFilters });
        //performSearch(newValue); // Avvia la ricerca dopo 1 secondo
      }, 500)
    );
  };

  const productContainsString = (product, searchString, language) => {
    // Converti la stringa di ricerca in minuscolo per la ricerca non case sensitive
    const lowerCaseSearchString = searchString.toLowerCase();

    // Controlla se il nome del prodotto contiene la stringa in modo non case sensitive
    const nameContains =
      product.product_name[language] &&
      product.product_name[language]
        .toLowerCase()
        .includes(lowerCaseSearchString);

    // Controlla se la descrizione del prodotto contiene la stringa in modo non case sensitive, se esiste
    const descriptionContains =
      product.product_description[language] &&
      product.product_description[language]
        .toLowerCase()
        .includes(lowerCaseSearchString);

    return nameContains || descriptionContains;
  };

  // Funzione per eseguire la ricerca
  const performTextSearch = (searchValue) => {
    if (searchValue) {
      console.log("searchValue:", searchValue);
      let excludedProductIds = []; // Array per raccogliere gli ID dei prodotti esclusi

      // Itera attraverso ogni sezione di menuData
      Object.values(menuData).forEach((section) => {
        // Controlla i prodotti diretti della sezione
        section.products.forEach((product) => {
          if (!productContainsString(product, searchValue, language)) {
            excludedProductIds.push(product.product_id);
          }
        });

        // Controlla i prodotti nelle sottosezioni
        Object.values(section.subSections).forEach((subSection) => {
          subSection.subSectionData.forEach((product) => {
            if (!productContainsString(product, searchValue, language)) {
              excludedProductIds.push(product.product_id);
            }
          });
        });
      });
      return [...new Set([...excludedProductIds])];
    } else {
      return [];
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
  };

  return (
    <Dialog
      open={open}
      fullScreen
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle
        sx={{
          background: theme.palette.primary.dark,
          color: "white",
          padding: "0px",
        }}
      >
        <RenderModalHeader />
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card sx={{ padding: "10px" }}>
              <Autocomplete
                multiple
                fullWidth
                id="combo-box-demo"
                options={ingredientNames}
                value={searchIngredient}
                style={{ background: "white", borderRadius: "5px" }}
                onChange={(event, newValue) => {
                  setSearchIngredient(newValue);
                  let productIds = [];
                  newValue.forEach((ingredientName) => {
                    if (ingredientToProductIds[ingredientName]) {
                      productIds.push(
                        ...ingredientToProductIds[ingredientName]
                      );
                    }
                  });
                  setProductIdIngredientSelected([...productIds]);
                  // Ora, `productIds` è un array di tutti i product_id associati ai nomi degli ingredienti selezionati.
                }}
                renderInput={(params) => (
                  <TextField
                    style={{ width: "100%" }}
                    {...params}
                    label={<Trans>Cerca per ingrediente</Trans>}
                  />
                )}
              />
              <TextField
                sx={{ marginTop: "10px" }}
                fullWidth
                label={<Trans>Cerca per testo</Trans>}
                variant="outlined"
                value={searchText}
                onChange={handleSearchChange}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            {AddCheckBox("allergens", <Trans>Escludi allergeni</Trans>, false)}
          </Grid>
          <Grid item xs={12}>
            {AddCheckBox("categories", <Trans>Includi categorie</Trans>, true)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ backgroundColor: theme.palette.primary.dark, padding: "5px" }}
      >
        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={4}
        >
          <Button
            startIcon={<FilterAltOffRoundedIcon />}
            style={{ height: "100%" }}
            variant="contained"
            color="error"
            onClick={() => clearFilters()}
          >
            <Trans>Cancella filtri</Trans>
          </Button>
          <Button
            startIcon={<FilterAltRoundedIcon />}
            style={{ height: "100%" }}
            variant="contained"
            color="secondary"
            onClick={() => (applyFilters(), setOpen(false))}
          >
            <Trans>Applica filtri</Trans>
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
